@import 'TelemedShared';

@tile-container-bg: #444;
@tile-container-meeting-end-color: @white;
@tile-container-all-description-color: @white;

.message-center-panel {
  display: flex;
  width: 450px;
  height: 100%;

  .fixed-wrapper {
    width: 100%;
    height: 100%;

    .fixed-div {
      height: 100%;
      max-width: 100%;
    }
    @media screen and (max-width: 600px) {
      width: 100vw !important;
    }

    .flex-message-center-attributes {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100%;

      .tile-container.center {
        justify-content: center;
      }
      .tile-container.space-between {
        justify-content: space-between;
      }

      .absolute {
        position: absolute !important;
        z-index: 100;
        width: 100%;
      }

      .tile-container {
        height: 100%;
        max-width: 100%;

        padding: 20px 10px 10px 10px;
        display: flex;
        flex: 2;

        flex-direction: column;
        position: relative;

        background-color: @tile-container-bg;

        .meeting-end {
          text-align: center;
          color: @tile-container-meeting-end-color;
          margin: 5px 0px;
        }

        .call-description {
          color: white;
          text-align: center;
          padding-bottom: 20px;
        }

        .large-video {
          height: 100% !important;
          margin: 0 !important;
        }
        .name-video-container {
          flex: 1;
          width: calc(100% - 20px);
          max-height: calc(100% - 120px);
          .meeting-name {
            background-color: @tile-container-bg;
            max-width: 100%;
            color: @white;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 20px 10px 10px 10px;
          }

          .video-window.collapse {
            display: none;
          }

          .video-window {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 100%;
            height: 100%;
            max-height: calc(100% - 50px);

            @media only screen and (max-height: 575.98px) and (orientation: landscape) {
              height: 43%;
            }

            .video-tile.collapse {
              display: none;
            }

            .video-tile {
              margin: 5px 5px;
              min-width: 0;
              min-height: 0;
              flex: 1 1 0;
              display: inline-block;
              max-height: 50%;

              @media only screen and (max-height: 575.98px) and (orientation: landscape) {
                max-height: none;
              }

              .video-tile-video {
                box-shadow: @shadow-high;
                border-radius: 12px 12px 12px 12px;
                object-fit: cover;
                height: 100%;
                width: 100%;
              }

              .video-tile-video.collapse {
                display: none;
              }

              .video-tile-video.contain {
                object-fit: contain !important;
              }

              .video-tile-icon {
                border-radius: 12px 12px 12px 12px;
                background-color: @telemed-button-bg;
                box-shadow: @shadow-high;
                width: 100%;
                height: 100%;
                aspect-ratio: 16 / 9;
              }

              .video-tile-icon.collapse {
                display: none;
              }
            }
            .call-left-copy-container {
              text-align: center;
              margin-bottom: 20px;
              color: white;
            }
          }
        }

        .rejoin-buttons-container {
          width: 100%;
          height: 50px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-family: 'Lato', sans-serif;

          #rejoin-buttons();
        }
        .small-screen-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          #app-nav();
          #call-buttons();
        }
      }
      .chat-panel {
        background-color: white;
        flex: 1;
      }
    }
  }
}

.message-center-panel.maximized {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  .fixed-wrapper {
    .fixed-div {
      .flex-message-center-attributes {
        .tile-container {
          @media (min-aspect-ratio: ~'1/1') {
            .name-video-container {
              width: 100%;
              display: flex;
              flex-direction: column-reverse;
              justify-content: space-between;
              .meeting-name {
                text-align: left;
              }
              .video-window {
                .video-tile {
                  max-height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.blink_me {
  animation: blinker 0.3s linear;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
