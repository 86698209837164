@import 'Fonts';
@import 'Env';
@import 'Colors';
@import 'FormControls';

@signup-content-color: @green-light;
@signup-data-capture-title-color: @green-dark;
@signup-data-capture-text-color: @black;

// Manual override for Safari-only bug
// https://www.ryadel.com/en/css3-media-query-target-only-ie-ie6-ie11-firefox-chrome-safari-edge/
_::-webkit-full-page-media,
_:future,
:root .signup-content {
  display: block;
}

#root {
  &[aria-hidden='true'] {
    .top-bar,
    .df-footer {
      z-index: unset;
    }
  }
}

.top-bar {
  .top-bar-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 18px;
    padding-right: 18px;
    background: @white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    h4 {
      #font.heading-4();
    }
  }
}

.explore {
  display: flex;
  flex: 1;
  flex-direction: column;

  .explore-step-progress {
    padding-left: 22px;
    padding-right: 32px;
    #font.body-large();
  }

  .alert-message-container {
    z-index: 9999;

    @media screen and (min-width: 600px) {
      z-index: 1000;
    }
  }

  .df-page {
    padding-bottom: 150px;
  }

  &.keyboard-opened {
    @media screen and (max-width: 600px) {
      .df-page {
        padding-bottom: 0;
      }
      .df-footer {
        position: relative;
        width: unset;
        box-sizing: unset;
        z-index: unset;
      }
    }
  }

  .df-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 9998;

    @media screen and (min-width: 600px) {
      width: unset;
      box-sizing: unset;
      z-index: unset;
    }
  }
}

.signup-content {
  background-color: @signup-content-color;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: 600px) {
    margin-top: 75px;
  }

  .signup-study-header {
    display: flex;
    padding: 16px;
    align-items: center;

    .signup-study-name {
      #font.heading-3();
    }

    .signup-study-logo {
      height: 50px;
    }
  }

  .prescreen-complete-container {
    width: 900px;
    margin: 0 auto;

    .prescreen-complete-title {
      #font.heading-3();
      margin: 45px 0 0;
    }

    .prescreen-complete-closing-p {
      margin: 48px 0 0;
    }

    .activate-now-button {
      #button.primary();
      max-width: 355px;
    }
  }
}

.data-capture-disclaimer {
  display: flex;
  flex-direction: column;
  margin: 28px;
  align-self: center;
  max-width: 540px;

  .title {
    #font.heading-1();
    color: @signup-data-capture-title-color;
    margin-bottom: 28px;
  }

  .text {
    #font.body-large();
    margin-bottom: 28px;
    line-height: 22px;
    color: @signup-data-capture-text-color;

    .attn {
      font-weight: 900;
    }
  }

  .button {
    #button.primary();
    max-width: 260px;
  }
}

.partner-redirect {
  display: flex;
  flex-direction: column;
  margin: 28px;
  align-self: flex-start;
  justify-self: flex-start;
  max-width: 540px;

  .title {
    #font.heading-1();
    color: @signup-data-capture-title-color;
    margin-bottom: 28px;
  }

  .text {
    #font.body-large();
    margin-bottom: 28px;
    line-height: 22px;
    color: @signup-data-capture-text-color;
  }
}

.trial-deactivated {
  display: flex;
  flex-direction: column;
  margin: 28px;
  align-self: flex-start;
  justify-self: flex-start;
  max-width: 540px;

  .title {
    #font.heading-1();
    color: @signup-data-capture-title-color;
    margin-bottom: 28px;
  }

  .text {
    #font.body-large();
    margin-bottom: 28px;
    line-height: 22px;
    color: @signup-data-capture-text-color;
  }
}
