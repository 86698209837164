@import 'Fonts';
@import 'Env';

@custom-dialog-bg: @white;
@custom-dialog-header-bg: #444;
@custom-dialog-header-color: @white;
@custom-dialog-header-button-color: #069;
@custom-dialog-close-button-color: @white;

.material-dialog {
  .dialog-body {
    margin: 20px 35px 25px;

    .dynamic-form-page {
      margin: 0;

      .dialog-body button {
        margin: 0;
      }
    }
  }

  .dialog-intro {
    margin-top: 0;
    font-size: 18px;
  }

  .dialog-content {
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .dialog-content li {
    line-height: 1.2;
    margin: 14px 0;
  }

  .custom-dialog-container {
    background-color: @custom-dialog-bg;
    max-width: @isTabletSizedMaxWidth;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .custom-dialog-header {
    background-color: @custom-dialog-header-bg;
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    flex-direction: row;
  }

  .custom-dialog-header-left {
    flex: 1;
    margin-right: 28px;
  }

  .custom-dialog-header-title {
    color: @custom-dialog-header-color;
    font-size: 26px;
  }

  .custom-dialog-header-subtitle {
    color: @custom-dialog-header-color;
    font-size: 14px;
    margin-top: 14px;
  }

  .custom-dialog-header-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: arial, sans-serif;
    color: @custom-dialog-header-button-color;
    margin-top: 10px;
    cursor: pointer;
    > button {
      padding: 0;
      margin-top: 10px;
      min-height: 0;
      min-width: 0;
    }
  }

  .custom-dialog-close-button-container {
    color: @custom-dialog-close-button-color;

    &:hover {
      cursor: pointer;
    }
  }
}
