@import 'Colors';

@ocp-option-bg: @white;
@ocp-option-border: @green-medium;
@ocp-right-bottom-color: #9c9c9c;
@ocp-right-top-color: #333;
@ocp-right-bottom-color: #9c9c9c;

.one-click-picker {
  display: flex;
  flex-direction: column;

  .ocp-option {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
    background: @ocp-option-bg;
    border: 1px solid @ocp-option-border;
    box-sizing: border-box;
    box-shadow: @shadow-high;
    border-radius: 5px;
    cursor: pointer;
    padding: 18px;
    align-items: center;

    .ocp-left {
      margin-right: 18px;
      img {
        width: 100%;
        height: 100%;
        max-width: 20pt;
        max-height: 20pt;
      }
    }

    .ocp-right {
      display: flex;
      flex-direction: column;

      .ocp-right-top {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: @ocp-right-top-color;
      }

      .ocp-right-bottom {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: @ocp-right-bottom-color;
      }
    }
  }
}
