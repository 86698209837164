.ctm-user {
  display: flex;
  flex-direction: row;
  margin-left: auto;

  .buttons {
    margin-left: auto;
    order: 2;
    align-self: flex-end;
  }
}
