@import 'Fonts';
@import 'Env';

.spv-pdf-view {
  display: block;
  padding: 20px;

  box-sizing: border-box;

  border: 1px solid #f3f4f5;
}

.spv-pdf-header {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  color: #000000;
}

.spv-pdf-logo {
  width: 150px;
}

.case-report-pdf-header {
  /* Frame 1 */

  box-sizing: border-box;

  /* Light/Surfaces/Secondary */

  background: #f3f4f5;
  /* Light/Surfaces/Tertiary */

  border: 1px solid #e9e9eb;
  border-radius: 3px;

  /* Label/Medium */

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.5px;

  color: #000000;
}
