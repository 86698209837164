@import 'Fonts';

@error-page-container-bg: #eee;

.error-page-container {
  background-color: @error-page-container-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.error-page-title {
  font-size: 32px;
  max-width: 650px;
  text-align: center;
  margin-bottom: 28px;
}

.error-page-icon {
  margin-bottom: 28px;
}

.error-page-text {
  font-size: 18px;
  max-width: 650px;
}

.error-id-text {
  text-align: center;
  font-size: 16px;
  color: lightgray;
}
