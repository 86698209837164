@import 'TelemedShared';

@flex-message-center-attributes-bg: #454545;
@flex-message-meeting-end-color: @white;

.message-center-panel-mobile {
  width: 100%;
  position: fixed; //fixed is absolute to a browser
  bottom: 0;
  left: 0;
  right: 0;

  .fixed-wrapper {
    height: 100%;
    display: flex;

    .fixed-div {
      width: 100% !important;
    }
    .flex-message-center-attributes {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: flex-start;
      background-color: @flex-message-center-attributes-bg;
      height: 160px;

      .absolute {
        position: absolute;
      }

      .tile-container {
        justify-content: space-evenly;
        display: flex;
        flex-direction: row;

        width: 100%;
        align-items: center;

        .rejoin-buttons-container {
          height: 50px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-family: 'Lato', sans-serif;

          #rejoin-buttons();
        }

        .meeting-end {
          text-align: center;
          color: @flex-message-meeting-end-color;
          margin: 5px 0px;
        }

        .small-screen-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 200px;

          #call-buttons();
          #app-nav();
        }

        .name-video-container {
          max-width: calc(~'100% - 200px');

          .meeting-name {
            background-color: @tile-container-bg;
            color: @white;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 5px 5px 5px 5px;
          }

          .video-window.collapse {
            display: none;
          }

          .video-window {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 0 1%;

            .video-tile {
              height: 120px;
              width: 100%;

              @media screen and (max-width: 350px) {
                width: 75px;
              }

              @media screen and (max-width: 280px) {
                width: 60px;
              }

              margin: 0 2% 0 2%;
              display: flex;
              justify-content: center;
              max-height: 100%;

              .video-tile-video {
                object-fit: cover;
                max-width: 100%;
                border-radius: 10px;
              }

              .video-tile-video.collapse {
                display: none;
              }

              .video-tile-video.contain {
                object-fit: contain !important;
              }

              .video-tile-icon {
                background-color: @telemed-button-bg;
                height: 120px;
                width: 100%;
                border-radius: 10px;
                box-shadow: @shadow-high;
              }

              .video-tile-icon.collapse {
                display: none;
              }
            }

            .video-tile.collapse {
              display: none;
            }
            .call-left-copy-container {
              text-align: center;
              margin-bottom: 20px;
              color: white;
            }
          }
        }
      }
    }
  }
}
.settings-overlay {
  margin: 10px;
  display: flex;
  flex-direction: column;
  position: relative !important;

  .close-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
