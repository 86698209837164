@import 'Env';

@header-reschedule-color: #3d8af7;
@participation-scores-status-good-color: #72bb53;
@participation-scores-status-bad-color: #e61610;

.header-tabledata .header-tabledata-row-right {
  line-height: 1.3em;

  .header-reschedule {
    text-transform: uppercase;
    color: @header-reschedule-color;
    margin-top: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  .header-address {
    display: flex;
    align-items: center;

    svg {
      fill: @header-reschedule-color;
      margin-left: 5px;
    }
  }

  .participation-scores {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .score {
      min-width: 250px;
      max-width: 472px;
      flex: 1;
      margin-right: 15px;

      .score-upper {
        display: flex;
        align-items: center;

        .percentage {
          font-size: 40px;
          margin-right: 17px;
        }
      }

      .status {
        display: flex;
        align-items: center;
        margin-top: 13px;

        svg {
          margin-right: 10px;
        }

        &.good {
          color: @participation-scores-status-good-color;
        }

        &.bad {
          color: @participation-scores-status-bad-color;
        }
      }
    }
  }
}

@media screen and (max-width: @isExtraSmallMaxWidth) {
  .subheader-container {
    margin-left: 18px;
  }

  .li-multi-container.inset {
    margin: 15px 18px 25px;
  }
}

@media screen and (max-width: 987px) {
  .header-tabledata .header-tabledata-row-right {
    .participation-scores .score {
      margin-top: 25px;
    }
  }
}
