@import 'Fonts';

@status-color-red-color: @status-color-red-text;
@status-color-gree-color: @status-color-green-text;
@status-color-yellow-color: @status-color-yellow-text;
@status-collor-gray-color: @status-color-gray-text; // #929292;

@status-color-filled-red-color: @status-color-red-text;
@status-color-filled-red-bg: @status-color-red-bg;

@status-color-filled-green-color: @status-color-green-text;
@status-color-filled-green-bg: @status-color-green-bg;
@status-color-filled-green-border: @status-color-green-text;

@status-color-filled-yellow-color: @status-color-yellow-text;
@status-color-filled-yellow-bg: @status-color-yellow-bg;

@status-color-filled-gray-color: @status-color-gray-text; // #929292;
@status-color-filled-gray-bg: @status-color-gray-bg;

.status-item-container {
  padding: 18px;
  padding-left: 28px;
}

.status-color-red {
  color: @status-color-red-color;
}

.status-color-green {
  color: @status-color-gree-color;
}

.status-color-yellow {
  color: @status-color-yellow-color;
}

.status-collor-gray {
  color: @status-collor-gray-color;
}

.status-color-filled {
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
}

.status-color-filled-red {
  .status-color-filled;
  color: @status-color-filled-red-color;
  background-color: @status-color-filled-red-bg;
}

.status-color-filled-green {
  .status-color-filled;
  color: @status-color-filled-green-color;
  background-color: @status-color-filled-green-bg;
  border-color: @status-color-filled-green-border;
}

.status-color-filled-yellow {
  .status-color-filled;
  color: @status-color-filled-yellow-color;
  background-color: @status-color-filled-yellow-bg;
}

.status-collor-filled-gray {
  .status-color-filled;
  color: @status-color-filled-gray-color;
  background-color: @status-color-filled-gray-bg;
}
