@import 'Fonts';

@trial-option-header-bg: @white;
@trial-option-header-border: #bbb;
@participant-tabs-svg-color: #c00;
@trial-row-participant-name-color: #434343;
@trial-row-participant-name-clickable-color: #00b0ff;
@participant-trial-container-subtag-border: #efefef;
@participant-identifier-color: rgba(0, 0, 0, 0.54);
@visit-method-bg: #606060;
@visit-method-color: @white;
@crf-container-summary-bg: #444;
@crf-container-summary-color: @white;
@crf-container-summary-red-bg: red;

@signoff-footer-bg: #eee;
@signoff-footer-border: #666;
@signoff-footer-color: #434343;
@signoff-footer-original-assessment-border: #666;

.trial-option-header {
  background-color: @trial-option-header-bg;
  border-bottom: 1px solid @trial-option-header-border;
  display: flex;
  flex-direction: column;
  padding: 20px 28px 0;

  .sticky {
    width: 100% !important;
    left: 0;
    z-index: 10;
    box-shadow: 0 0 3px 3px rgb(0 0 0 / 10%);
    background-color: @trial-option-header-bg;
    .trial-row {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 28px;
      padding-right: 28px;

      .non-arrow {
        @media screen and (max-width: @isExtraSmallMaxWidth) {
          .participant-trial-container {
            font-size: 11px;
          }

          .participant-tag.subtag {
            font-size: 10px;
          }
        }

        .participant-name {
          font-size: 16px;
        }
      }
    }
  }
}

.participant-tabs {
  margin-top: 15px;

  .needs-account .MuiTab-wrapper {
    flex-direction: row;

    svg {
      margin-left: 8px;
      color: @participant-tabs-svg-color;
      margin-top: 4px;
      order: 2;
    }
  }
}

.li-container .trial-row .img-container {
  margin: 0;
}

.trial-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .participant-name,
  .participant-trial-container,
  .participant-tag.subtag {
    transition: all 0.2s;
  }

  .non-arrow {
    display: flex;
    align-items: center;

    @media screen and (max-width: @isExtraSmallMaxWidth) {
      flex-direction: column;
      align-items: flex-start;

      .participant-name {
        margin-bottom: 12px;
      }
    }

    .participant-name {
      color: @trial-row-participant-name-color;
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
      margin-right: 13px;

      &.clickable {
        color: @trial-row-participant-name-clickable-color;
      }
    }

    .participant-trial-container {
      display: flex;
      margin-top: 0;
      margin-right: 15px;
      font-size: 14px;

      .participant-tag {
        padding: 7px 10px;
        border-radius: 3px;

        &.subtag {
          padding: 8px 9px 7px;
          margin-left: -1px;
          border-radius: 0;
          border: 1px solid @participant-trial-container-subtag-border;
          border-left: none;
          font-size: 13px;
        }
      }
    }
  }
}

.identifier-row {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  font-size: 16px;

  @media screen and (max-width: @isExtraSmallMaxWidth) {
    margin-top: 12px;
  }

  .participant-identifier {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 3px;
    color: @participant-identifier-color;

    svg {
      margin-right: 7px;
      font-size: 21px;
    }

    a {
      text-decoration: none;
      color: @participant-identifier-color;

      &:hover {
        cursor: pointer;
      }

      &:visited {
        color: @participant-identifier-color;
      }
    }
  }
}

.visit-method {
  padding-left: 25px;
  padding-right: 25px;
  background-color: @visit-method-bg;
  color: @visit-method-color;
  margin-left: 10px;
  border-radius: 5px;
}

.crf-container {
  margin: 15px 28px 200px;
  border-radius: 2px;

  .expansion-summary-content {
    display: flex;
    width: 100%;
    align-items: center;

    .summary-time {
      background: @crf-container-summary-bg;
      padding: 5px 10px;
      color: @crf-container-summary-color;
      font-weight: 600;
      text-align: right;
      min-width: 128px;
      margin-right: 38px;

      &.red {
        background: @crf-container-summary-red-bg;
      }
    }

    .summary-title {
      flex: 1;
      font-size: 20px;
    }
  }

  .expansion-details-content {
    flex-direction: column;

    .data-message {
      margin-bottom: 24px;
      font-size: 15px;
      font-weight: normal;
      font-style: italic;
    }
  }

  .expansion-inner-content {
    margin-left: 186px;
  }
}

.signoff-footer {
  align-items: center;
  background: @signoff-footer-bg;
  border-color: @signoff-footer-border;
  border-style: solid;
  border-width: 4px 0px 0px 0px;
  bottom: 0px;
  color: @signoff-footer-color;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  position: fixed;
  width: 100%;
  z-index: 10;
  .attestation-container {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    .originalAssessment {
      border-width: 2px;
      border-color: @signoff-footer-original-assessment-border;
      border-style: solid;
      padding: 5px;
    }
    .attestations {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .attestation {
        display: flex;
        cursor: pointer;
        align-items: flex-start;
        padding: 5px 5px 0px 0px;
        .attestation-checkbox {
          margin-top: -10px;
        }
        .attestation-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .sub-text {
            padding: 5px 5px 5px 0px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .instructions {
    align-self: center;
    padding-bottom: 10px;
  }
  .signoff-botton {
    align-self: flex-end;
    margin-right: 30px;
    padding-right: 10px;
  }
}

@media (max-width: 449px) {
  .participant-language-container {
    width: 300px;
    padding-top: 30px;
  }
}

@media (min-width: 450px) {
  .participant-language-container {
    margin: 0 auto;
    width: 520px;
    padding-top: 30px;
  }
}

.unscheduled-activities-instructions {
  font-size: 18px;
}

.participant-casebook-binder {
  margin-left: 26px;
  margin-right: 26px;
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.participant-epoch-group-container {
  margin-bottom: 20px;
  margin-top: 20px;
  .participant-epoch-group-label {
    font-weight: bold;
    margin-left: 28px;
  }
}
