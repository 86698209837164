.data-capture-review,
.data-capture {
  min-height: 100vh;

  .sticky {
    .header-subtext {
      display: none;
    }
  }

  .data-message {
    font-size: 15px;
    font-weight: normal;
    font-style: italic;
    text-align: center;
    margin: 1rem 0 1rem 0;
  }
}
