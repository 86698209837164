.d2h-tag.d2h-moved.d2h-moved-tag {
  display: none;
}

.diff-viewer {
  width: 100%;
}

.diff-viewer-loader {
  padding: 20;
  text-align: center;
}
