@import 'Colors';

@sc-preview-bg: #eee;

@mpc-border-color: #aaa;
@mpc-exemple-border-color: #ddd;
@mpc-exemple-bg: #111;

.SCSection {
  padding: 0 !important;
  position: relative;
  height: 1100px;

  box-shadow: @shadow-thin;

  & hr {
    border-top: 1px solid @gray-light;
  }
}

.SCTools {
  width: 200px;
  padding: 0;
  padding: 20px;
  border-right: 1px solid @gray-light;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  & .SCMenuTitle {
    margin-top: 0;
    margin-bottom: 1em;
  }

  & .SCLogoContainer {
    margin-bottom: 1em;
  }
}

.SCViewport {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 241px;
  right: 0;

  & .SCViewportTabs {
    background-color: @white;
    height: 48px;
    border-bottom: 1px solid @gray-light;
  }

  & .SCPreview {
    background: @sc-preview-bg;
    position: absolute;
    top: 49px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.UploadImageContainer {
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f3f4f5;

  & .ImagePreviewBorderRadius {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

/*
* Prescreen Body
*/

.StepperContainer {
  height: 90px;
  background-color: @green-dark;
  padding: 20px 0px;
}

.PreviewStepLabel {
  display: inline-block;
  width: 64px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.4);
}

.PrescreenBody {
  padding: 20px 100px;

  & .ClipartRow {
    text-align: center;
    padding: 36px 0;
  }

  & .LinePlaceholder {
    display: inline-block;
    height: 22px;
    min-width: 120px;
    background-color: #ddd;
    border-radius: 4px;
  }

  & .PreviewDivider {
    margin-bottom: 30px;
  }

  & .PreviewControllers {
    text-align: right;
  }
}

/*
* Email Communication
*/

.EmailPreview {
  & > .EmailHead {
    padding: 38px 20px;
    background-color: @green-dark;
    text-align: center;
  }

  & > .EmailAlignCenter {
    text-align: center;
  }

  & > .EmailBody {
    padding: 15px 104px;
  }

  & > .EmailList {
    padding: 20px 104px;
    text-align: justify;
    font-size: 13px;

    & > p {
      margin-bottom: 15px;
    }

    & > ul {
      padding: 0px 16px 0px 24px;

      & > li {
        margin-bottom: 7px;
      }
    }
  }

  & .EmailMarginBottom {
    margin-bottom: 20px;
  }

  & .EmailFooter {
    background-color: @gray-light;
    padding: 15px 25px;
    font-size: 12px;

    & p {
      color: @gray-medium;
    }
  }
}

.DashboardPreview {
  flex: 1;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #fefefe;
  padding-bottom: 20px;

  & .Navbar {
    padding: 24px 16px;
  }

  & .Body {
    padding: 5px 30px;

    & .CardContainer {
      padding: 10px 0px;
      height: 450px;
      width: 950px;
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  & .MarginBody {
    margin-bottom: 10px;
  }

  & .CardHeader {
    margin-bottom: 24px;
    position: relative;
  }

  & .CardIconWrapper {
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 60px;
    border-radius: 50%;
    width: 49px;
    height: 49px;
    margin: -60px;
  }

  & .CardIcon {
    width: 25px;
    height: 25px;
    padding: 12px;
    display: inline-block;
    border-radius: 50%;
  }
}

.NextStepContainer {
  border-left: 3px solid @green-dark;
  padding-left: 28px;
}

.MyColorPickerCover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.MyColorPickerPopover {
  position: absolute;
  z-index: 2;
}

.MyColorPicker {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px solid @mpc-border-color;
  margin-bottom: 20px;

  & .ColorCloseButton {
    margin-right: 10px;
  }

  & .ColorLabel {
    display: inline-block;
    flex-grow: 1;
  }

  & .ColorExample {
    border: 1px solid @mpc-exemple-border-color;
    width: 20px;
    height: 20px;
    background-color: @mpc-exemple-bg;
    display: inline-block;

    &.noColorSelected {
      background: linear-gradient(
        to top left,
        rgba(255, 0, 0, 0) 0%,
        rgba(255, 0, 0, 0) calc(50% - 0.8px),
        rgba(255, 0, 0, 1) 50%,
        rgba(255, 0, 0, 0) calc(50% + 0.8px),
        rgba(255, 0, 0, 0) 100%
      );
    }
  }
}
