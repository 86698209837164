@join-button-bg: #a9c6fa;
@join-button-color: @white;
@join-button-hover-color: @black;

@telemed-button-bg: #606060;
@telemed-button-color: @white;
@telemed-button-border: #c0c0c0;
@telemed-button-hover-color: @black;
@telemed-button-hover-bg: #a9c6fa;

@telemed-button-off-bg: @telemed-button-color;
@telemed-button-off-color: @telemed-button-bg;

@telemed-button-off-hover-color: @black;

@app-nav-small-screen-color: @white;
@app-nav-small-screen-bg: #444444;
@app-nav-small-screen-hover-color: #a9c6fa;

@rejoin-button-bg: #a9c6fa;
@rejoin-button-color: @white;

@rejoin-button-hover-bg: #a9c6fa;
@rejoin-button-hover-color: @black;

@green-medium: #499297;

#actual-buttons() {
  .end-call {
    height: 45px !important;
    width: 45px !important;
    border: 0px !important;
  }

  .join-button {
    background-color: @join-button-bg;
    margin: 0px 10px;
    height: 40px;
    color: @join-button-color;
    font-size: 11px;
  }
  .join-button:hover {
    color: @join-button-hover-color;
  }

  .telemed-button {
    height: 40px;
    width: 40px;
    margin: 0px 5px;

    background-color: @telemed-button-bg;
    border-radius: 3;
    color: @telemed-button-color;
    border: 1px solid @telemed-button-border;
  }
  .telemed-button:hover {
    background-color: @telemed-button-hover-bg;
    color: @telemed-button-hover-color;
  }
  .telemed-button.off {
    background-color: @telemed-button-off-bg;
    color: @telemed-button-off-color;
  }
  .telemed-button.off:hover {
    color: @telemed-button-off-hover-color;
  }
}

#call-buttons() {
  .options {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 5px;
    flex-wrap: wrap;

    #actual-buttons();
  }
}

#app-nav() {
  .app-nav-small-screen {
    color: @app-nav-small-screen-color;
    background-color: @app-nav-small-screen-bg;
    border-width: 0px;
    display: flex;
    align-items: center;
  }

  .app-nav-small-screen:hover {
    color: @app-nav-small-screen-hover-color;
  }
}

#rejoin-buttons() {
  .rejoin-button {
    background-color: transparent;
    margin: 0px 10px;
    color: @rejoin-button-color;
  }
  .done-button {
    background-color: white;
    margin: 0px 10px;
    color: @green-medium;
    width: 100px;
    border-radius: 10px;
    font-weight: bolder;
  }
}
