@import 'Colors';

@auto-complete-input-bg: #e1e1e1;

.Autocomplete {
  background-color: @white;
  overflow: hidden !important;
  border-radius: 5px;
  box-shadow: 0px 1px 3px @shadow-default;

  & .MuiInputBase-input {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  & > .MuiFormControl-root,
  & > .MuiFormControl-root > .MuiInputBase-root {
    border-color: @auto-complete-input-bg;
  }

  & > .MuiFormControl-root > .MuiInputBase-root:hover > input {
    border-color: @auto-complete-input-bg;
  }

  & .MuiChip-root {
    height: 28px;
  }
}
