@import 'Fonts';
@import 'Env';

@data-capture-missed-page-bg: #ecf4f5;
@data-capture-title-color: #2d6d88;

.data-capture-missed-page {
  background-color: @data-capture-missed-page-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
  justify-content: flex-start;

  .data-capture-missed-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-evenly;
    text-align: left;
    min-height: 300px;
    max-height: 400px;

    .margin-assist {
      display: flex;
      width: 85%;
      flex-direction: column;
      justify-content: space-evenly;
      min-height: 300px;
      max-height: 400px;

      .title {
        display: flex;
        color: @data-capture-title-color;
        #font.heading-1();
      }

      .sub-title {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        color: @data-capture-title-color;
        #font.body-medium();
        font-size: 20px;
        opacity: 0.7;
      }

      .button {
        display: flex;
      }
    }
  }
}
