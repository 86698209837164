@import 'TelemedShared';

@filler-div-color: #000;
@device-changer-color: #444;
@photo-booth-menu-button-bg: #606060;
@photo-booth-menu-button-color: @white;
@photo-booth-menu-button-hover-bg: #a9c6fa;
@photo-booth-menu-button-hover-color: #black;
@device-changer-select-obj-header-color: @white;
@device-changer-select-obj-button-color: @white;

.settings-overlay {
  z-index: 10;
  position: absolute;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .filler-div {
    flex: 1;
    background-color: @filler-div-color;
    opacity: 0.5;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .device-changer {
    flex: 2;
    background-color: @device-changer-color;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 10px 10px;
    opacity: 1 !important;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-family: 'Lato', sans-serif;
    font-size: 11px;

    .photo-booth-menu {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;

      .general-menu-button {
        margin-top: 15px !important;
        margin: 0px 10px;
        height: 40px;
        background-color: @photo-booth-menu-button-bg;
        color: @photo-booth-menu-button-color;
        font-size: 11px;
        border-radius: '20px 20px 20px 20px';
      }

      .general-menu-button:hover {
        background-color: @photo-booth-menu-button-hover-bg !important;
        color: @photo-booth-menu-button-hover-color !important;
      }
    }

    .select-obj {
      padding: 10px 0px;
      .header-text {
        color: @device-changer-select-obj-header-color;
      }
    }
    .close-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      color: @device-changer-select-obj-button-color !important;
    }
  }
}
