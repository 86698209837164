@image-gallery-section-bg: @white;
@image-gallery-section-shadow: rgba(161, 161, 161, 0.1);
@image-gallery-image-caption-bg: rgba(0, 0, 0, 0.5);
@image-gallery-image-loading-bg: rgba(0, 0, 0, 0.5);

.image-gallery-section {
  background-color: @image-gallery-section-bg;
  box-shadow: 0px 1px 2px 1px @image-gallery-section-shadow;
  border-radius: 2px;
  margin: 25px 50px;
  padding: 20px;
}

.image-gallery-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image-gallery-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px;
}

.image-gallery-image-container {
  margin: 5px 5px 5px 5px;
  position: relative;

  & > img {
    width: 220px;
    height: 220px;
  }
}

.image-gallery-image-caption {
  background-color: @image-gallery-image-caption-bg;
  width: 220px;
  height: 90px;
  margin-top: -93px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-gallery-image-loading {
  background-color: @image-gallery-image-loading-bg;
  width: 220px;
  height: 219px;
  margin-top: -222px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
