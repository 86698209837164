@import 'Fonts';

.bullets-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 14px;
  }

  svg {
    width: 48px;
  }
}

.bullets-row-right {
  font-size: 14px;
  flex: 1;
}
