@import 'Colors';

@action-card-bg: @card-default-bg;
@ac-icon-radial-bg: rgba(73, 146, 151, 0.05);
@ac-due-date-container-bg: @white;
@ac-inner-container-bottom-color: #333;
@ac-button-container-color: @white;
@ac-button-disabled-color: rgba(73, 146, 151, 0.2);
@ac-more-actions-color: #817b7b;
@ac-is-overdue-color: @pink;
@ac-is-overdue-card-shadow: rgba(233, 153, 143, 0.3);
@ac-is-overdue-ac-icon: @white;
@ac-overdue-due-date-container-bg: rgba(256, 256, 256, 0.2);
@ac-due-date-container-color: @white;
@ac-gutter-button-bg: @white;
@ac-gutter-button-color: @pink;

#width-of-card() {
  @media screen and (max-width: 500px) {
    width: calc(100vw - 95px);
  }

  @media screen and (min-width: 500px) {
    width: 400px;
  }
}

#height-of-card() {
  @media screen and (max-width: 500px) {
    min-height: 360px;
  }

  @media screen and (min-width: 500px) {
    min-height: 384px;
  }
}

.action-card-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-left: 28px;
  padding-top: 28px;
  padding-bottom: 28px;

  .action-card-container {
    padding-right: 28px;
    align-self: stretch;
  }

  .action-card {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    background: @action-card-bg;
    border-radius: 10px;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: @shadow-high;
    padding-bottom: 18px;

    #width-of-card();
    #height-of-card();

    .ac-tag {
      position: absolute;
      top: 34px;
      right: 28px;
    }

    .ac-icon-section-container {
      position: absolute;
      width: 24px;
      height: 32px;
      left: 40px;
      top: 33px;

      .ac-icon-large-radial {
        position: absolute;
        background: @ac-icon-radial-bg;
        width: 240px;
        height: 240px;
        left: -110px;
        top: -110px;
        border-radius: 50%;
      }

      .ac-icon-small-radial {
        position: absolute;
        background: @ac-icon-radial-bg;
        width: 70px;
        height: 70px;
        left: -23px;
        top: -19px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
      }

      .ac-icon-container {
        align-self: center;
        flex-shrink: 1;
      }
    }

    .ac-icon {
      fill: @green-medium;
    }

    .ac-due-date-container {
      position: absolute;
      top: 20px;
      right: 20px;
      background: @ac-due-date-container-bg;
      border-radius: 5px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: @green-medium;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .ac-inner-container {
      display: flex;
      flex-direction: column;
      margin-top: 100px;
      margin-left: 28px;
      margin-right: 28px;
      border-radius: 5px;
      flex: 1;
    }

    .ac-inner-container-top {
      flex: 1;
      font-family: Lora;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      margin-right: 60px;
      z-index: 1;
    }

    .ac-inner-container-bottom {
      margin-top: 18px;
      margin-bottom: 18px;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: @ac-inner-container-bottom-color;
      .card-date {
        display: flex;
        .card-calendar-icon {
          margin-right: 8px;
        }
        .card-label {
          letter-spacing: 0.5px;
        }
      }
      .card-time {
        display: flex;
        margin-top: 4px;
        .card-calendar-icon {
          margin-right: 4px;
        }
        .card-label {
          letter-spacing: 0.5px;
        }
      }
    }

    .ac-gutter {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;

      .ac-button-container {
        display: flex;
        color: @ac-button-container-color;
        justify-content: flex-end;
        margin-right: -18px;

        .ac-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: @green-medium;
          border-radius: 5px;
          font-family: Lato;
          font-style: normal;
          font-weight: 900;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: @ac-button-container-color;
          padding-top: 18px;
          padding-bottom: 18px;
          margin-top: 0px;
          flex: 1;
          cursor: pointer;
          height: 20px;
          margin-right: 18px;
          hyphens: auto;
          text-align: center;
          flex-grow: 2;

          &.ac-button-disabled {
            opacity: 0.1;
            cursor: default;
          }

          &.ac-button-secondary {
            flex-grow: 1;
          }
        }
        .viewDetails-btn-secondary {
          background-color: white;
          border: 1px solid;
          color: initial;
        }
        .rejoin-button {
          background: transparent;
        }
        .ac-more-actions {
          display: flex;
          #font.body-medium();
          font-weight: bold;
          color: @ac-more-actions-color;
          cursor: pointer;
          text-align: left;
          width: 50px;
          margin-left: 0px;
          margin-right: 10px;
          align-items: center;
        }
      }
    }

    &.is-overdue {
      background: @ac-is-overdue-color;
      box-shadow: 0px 5px 20px @ac-is-overdue-card-shadow;

      .action-card {
        background: @ac-is-overdue-color;
        box-shadow: 0px 5px 20px @ac-is-overdue-card-shadow;
      }

      .ac-icon-large-radial {
        background: @ac-is-overdue-ac-icon;
        opacity: 0.05;
      }

      .ac-icon-small-radial {
        background: @ac-is-overdue-ac-icon;
        opacity: 0.05;
      }

      .ac-icon path {
        fill: @ac-is-overdue-ac-icon;
      }

      .ac-due-date-container {
        background-color: @ac-overdue-due-date-container-bg;
        color: @ac-due-date-container-color;
      }

      .ac-inner-container-top {
        text-decoration-line: line-through;
        color: @ac-due-date-container-color;
      }

      .ac-inner-container-bottom {
        color: @ac-due-date-container-color;
      }

      .ac-gutter {
        .ac-button {
          background: @ac-gutter-button-bg;
          color: @ac-gutter-button-color;
        }

        .ac-more-actions {
          color: @ac-gutter-button-bg;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .action-card-list-mobile {
    flex-direction: column;
    flex-wrap: unset;
    align-content: space-between;
    padding: 2rem 1rem 1rem 1rem;
    .action-card-container {
      padding-right: 16px;
      padding-left: 16px;
      align-self: stretch;
    }
    .action-card {
      margin: 0 0 1rem 0;
      height: unset;
      width: unset;
      .ac-inner-container-top {
        font-size: 22px;
      }
      .ac-inner-container-bottom {
        margin-bottom: 1rem;
      }
    }
  }
}
