.drugbank-list {
  display: flex;
  flex-direction: column;

  .add-medication-button {
    margin-top: 10px;
  }

  .no-medications-to-report {
    margin-left: 24px;
  }

  .divider {
    margin-top: 20px;
    width: 100%;
    border-top: 1px solid #232425;
  }

  .medication-elem {
    margin-top: 15px;
  }

  .add-medication {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }
}

.medication-elem {
  display: flex;
  flex-direction: column;
  .spacer {
    margin-bottom: 10px;
  }
  .medication-row {
    display: flex;
    flex-direction: row;

    .start-picker {
      flex: 4;
    }

    .other-medication {
      margin-left: 10px;
    }

    .to {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
    .end-picker {
      flex: 4;
    }

    input {
      border: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }

    .autocomplete-container {
      flex: 9;
      background-color: white;
    }

    .del-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .del-svg {
        font-size: 30px;
      }
    }
  }
}
