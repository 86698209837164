@import 'Colors';
@import 'Fonts';

@cr-card-bg: @white;
@cr-card-bg-border: @green-medium;
@cr-right-top-color: #333;
@cr-right-bottom-color: #9c9c9c;

.card-roll {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-x: scroll;
  padding: 18px;

  .cr-card {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 80%;
    overflow: auto;
    background: @cr-card-bg;
    border: 1px solid @cr-card-bg-border;
    box-sizing: border-box;
    box-shadow: @shadow-high;
    border-radius: 5px;

    @media screen and (max-height: 699px) {
      padding-left: 10px;
      padding-bottom: 28px;
      padding-top: 18px;
      margin-right: 28px;
    }

    @media screen and (min-height: 700px) {
      padding-left: 10px;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-right: 28px;
    }

    cursor: pointer;

    .cr-left {
      @media screen and (max-width: 399px) {
        margin-left: 10px;
        margin-right: 18px;
        margin-top: 5px;
      }

      @media screen and (min-width: 400px) {
        margin-left: 4px;
        margin-right: 12px;
        margin-top: 5px;
      }
    }

    .cr-right {
      #font.body-medium();

      .cr-right-top {
        font-weight: bold;
        line-height: 20px;
        color: @cr-right-top-color;
      }

      .cr-right-bottom {
        line-height: 20px;
        color: @cr-right-bottom-color;
      }
    }
  }

  .card-roll-right-buffer {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(20% - 12px);
  }
}
