@import 'Fonts';
@import 'Env';

.subheader-container {
  font-weight: bold;
  margin-bottom: 15px;
  margin-left: 28px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subheader-title {
  flex: 1;
  margin-top: 10px;
}
.subheader-subtext {
  font-weight: normal;
  font-size: small;
  margin-top: 0.25rem;
}

.subheader-menu {
  margin-right: 28px;
}

.subheader-button {
  margin-right: 28px;
}

@media screen and (max-width: @isExtraSmallMaxWidth) {
  .subheader-menu {
    max-width: 120px;
    margin-top: 10px;
  }
  .subheader-button {
    max-width: 200px;
  }
}
