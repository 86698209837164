@import 'Fonts';
@import 'Env';

@trial-selector-bg: #ecf4f5;
@trial-selector-container-title-color: #2d6d88;

.trial-selector {
  background-color: @trial-selector-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
  justify-content: flex-start;

  .trial-selector-container {
    background-color: @trial-selector-bg;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 40%;
    align-items: center;
    justify-content: space-evenly;
    text-align: left;
    min-height: 300px;
    max-height: 400px;

    .trial-list {
      width: 100%;

      .title {
        display: flex;
        color: @trial-selector-container-title-color;
        #font.heading-1();
        margin-bottom: 1em;
      }
    }
  }
}
