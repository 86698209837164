@import 'Env';

@randomization-no-patients-text-color: #a4a4a4;

.randomization-block {
  padding: 0.5em;
  margin-right: 0.5em;
}

.randomization-no-patients-text {
  color: @randomization-no-patients-text-color;
}
