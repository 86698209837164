@import 'Colors';

@df-body-back-button-color: @gray-medium;
@consent-notes-font-color: @gray-medium;

.consent-call-context {
  margin: 30px;

  .image-container {
    text-align: center;
  }

  .li-multi-container.inset {
    margin: 0 0 30px;
  }

  p {
    max-width: 550px;
    font-family: Montserrat;
    margin: 30px auto 40px;
  }

  .embedded-scheduler-container {
    @media screen and (min-width: 600px) {
      width: 500px;
    }
  }
}

.informed-consent {
  .ic-header {
    margin-bottom: 25px;

    .ic-header-documents {
      text-transform: uppercase;
      color: @gray-dark;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    .ic-header-subtext {
      display: flex;

      svg {
        margin-right: 10px;
      }

      .ic-header-subtext-top {
        #font.label();
        margin-bottom: 2px;
      }

      .ic-header-subtext-bottom {
        #font.body-small();
        color: @black;
      }
    }
  }

  .df-body {
    .ic-back-button {
      font-weight: bold;
      line-height: 16px;
      width: 41px;
      height: 41px;
      cursor: pointer;
      color: @df-body-back-button-color;
      font-size: 40px;
      margin-top: 20px;
      margin-right: 5px;
    }
    justify-content: center;
    .df-page {
      max-width: 870px;
      margin-bottom: 120px;
    }
  }

  .knowledge-content-container {
    font-family: Times;
  }

  @media screen and (max-width: 800px) {
    .knowledge-content-container {
      padding: 10px;
    }
  }

  .version-tag {
    margin-left: 10px;
  }

  .document-list-item-title {
    display: flex;
  }
}

.signature-capture .booker-title {
  margin: 18px 60px 0 20px;
}

.signature-capture {
  background: #fff !important;
}

.consent-notes {
  width: 20vw;
  margin-top: 72px;
  margin-right: 20px;

  .cn-header {
    #font.label();
    color: @green-dark;
  }

  .cn-form {
    display: flex;
    flex-direction: column;
    background-color: @white;
    border-radius: 5px;
    padding: 15px;
    margin-top: 20px;

    .cn-label {
      #font.label();
      color: @consent-notes-font-color;
      margin-bottom: 10px;
    }

    .cn-input {
      margin-bottom: 20px;
    }

    .cn-error-text {
      #font.body-small();
      color: @pink;
      margin-bottom: 5px;
    }

    .cn-submit {
      margin-top: 5px;
      max-width: 100px;
    }
  }

  .cn-list {
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow: scroll;
    margin-top: 20px;

    .cn-list-item {
      display: flex;
      flex-direction: column;
      background: @white;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 20px;

      .cn-item-number {
        #font.body-medium();
        color: @black;
        font-weight: bold;
      }

      .cn-item-time {
        #font.body-small();
        color: @consent-notes-font-color;
        margin-top: 5px;
      }

      .cn-item-label {
        #font.body-medium();
        color: @black;
        font-weight: bold;
        margin-top: 15px;
      }

      .cn-item-text {
        #font.body-medium();
        color: @black;
        margin: 10px 0px 0px 10px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .consent-notes {
    width: 30vw;
  }
}

@media screen and (max-width: 800px) {
  .signature-capture .booker-title {
    margin-left: 9px;
  }
}
