@import 'Fonts';

@text-color: rgba(12, 12, 13, 0.64);

.password-requirements-container {
  #font.body-medium();

  .requirements-header {
    color: @text-color;
  }

  .requirement-text {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: @text-color;

    .requirement-icon {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 5px;
    }
  }
}
