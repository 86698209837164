@import 'Fonts';

@chart-section-bg: @white;

.sponsor-dashboard {
  display: flex;
  flex-direction: column;
}

.chart-section {
  display: flex;
  flex-direction: column;
  background-color: @chart-section-bg;
  margin: 28px;
  padding: 28px;
  &:first-child {
    margin-top: 48px;
  }
  &:not(:first-child) {
    margin-top: 48px;
  }
}

.chart-section-header {
  margin-left: 28px;
  margin-bottom: 28px;
  font-size: 28px;
}

.chart-section-body {
  display: flex;
  flex-direction: row;
}

.chart-section-body-left {
  flex: 1;
  margin-right: 28px;
}

.chart-section-body-right {
  width: 360px;
  margin-left: 20px;
  margin-right: 30px;
}

.sponsor-dashboard-table {
  line-height: 24px;
}

.sponsor-dashboard-table-header {
  font-weight: bold;
}

.sponsor-dashboard-table-row {
  display: flex;
  flex-direction: row;
}

.sponsor-dashboard-table-row-left {
  flex: 0.7;
}

.sponsor-dashboard-table-row-right {
  flex: 0.3;
}

.sponsor-dashboard-group-select {
  width: 360px;
  display: flex;
  flex-direction: column;
}

.sponsor-dashboard-group-select-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.sponsor-dashboard-group-select-dropdown {
}
