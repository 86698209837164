@import 'Fonts';
@import 'Colors';

@participant-data-capture-review-container-bg: @green-light;
@participant-dc-review-header-color: #2d6d88;

.participant-data-capture-review-container {
  background-color: @participant-data-capture-review-container-bg;
  min-height: 100%;
}
.participant-data-capture-review {
  align-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.participant-dc-review-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: @participant-dc-review-header-color;

  .participant-dc-review-header-top-text {
    #font.heading-1();
    padding: 16px;
    max-width: 520px;
  }

  .participant-dc-review-header-bottom-text {
    #font.body-large();
    padding: 16px 8px 44px;
    opacity: 0.7;
    max-width: 520px;
  }
}

.participant-dc-review-footer {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  padding-top: 16px;
  .participant-dc-review-button {
    padding-right: 16px;
  }
  .participant-review-eligible {
    height: 300px;
    width: auto;
  }
}
