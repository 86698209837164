@import 'Fonts';

@report-table-body-row-odd-bg: @white;
@report-table-body-row-even-bg: #eee;
@report-table-body-row-border: #bbb;
@report-table-unknown-value-color: #777;

.report-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 48px;
}

.report-table-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.report-table-header-column {
  flex: 1;
  margin-left: 28px;
  margin-bottom: 18px;
  font-weight: bold;
}

.report-table-body {
  width: 100%;
}

.report-table-body-row {
  display: flex;
  flex-direction: row;

  &:nth-child(odd) {
    background-color: @report-table-body-row-odd-bg;
  }

  &:nth-child(even) {
    background-color: @report-table-body-row-even-bg;
  }

  border-color: @report-table-body-row-border;
  border-style: solid;
  border-width: 0px;

  &:first-child {
    border-top-width: 1px;
  }

  &:last-child {
    border-bottom-width: 1px;
  }
}

.report-table-body-row-column {
  flex: 1;
  margin-left: 28px;
  margin-top: 18px;
  margin-bottom: 18px;
  word-wrap: break-word;
  word-break: break-all;
}

.report-table-unknown-value {
  color: @report-table-unknown-value-color;
  font-style: italic;
}
