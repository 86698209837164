.signup-block {
  min-height: 700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .content {
    width: 441px;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 28px;
      line-height: 36px;
    }

    .description {
      font-size: 16px;
      line-height: 24px;
    }

    .actions {
      align-self: flex-end;
    }
  }
}
