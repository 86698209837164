@file-preview-no-img-bg: #ebebeb;
@loading-container-bg: #000;
@telehealth-upload-text-color: #000;

.telehealth-file-upload-container {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.file-preview-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;

  .no-image-block {
    height: 100px;
    width: 100%;
    background-color: @file-preview-no-img-bg;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.telehealth-preview-container {
  display: flex;
  width: 150px;
  height: 100px;
  margin: 5px;
}

.preview-element {
  width: 100%;
  height: 100%;
}

.img-holder-file-upload {
  position: relative;
  margin: 5px;

  .deleting {
    height: 100%;
  }
  .delete-button-row {
    position: absolute;
    width: 100%;

    .loading-container {
      background-color: @loading-container-bg;
      opacity: 0.5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .delete-button {
      height: 15px;
      width: 15px;
      left: 110px;
    }
  }
}

.telehealth-unlock-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .telehealth-upload-text {
    height: 31px;
    color: @telehealth-upload-text-color;
    font-family: 'Helvetica';
    font-weight: 400;
    line-height: 1.3;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}
