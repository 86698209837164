.top-language-selector {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 20px;
}

.mobile {
  position: relative;
  top: 75px;
  z-index: 20;
}
