@import 'Fonts';
@import 'Colors';

@file-preview-thumbnail-color: @black;
@file-preview-thumbnail-bg: @white;
@file-preview-input-upload-color: #434343;
@file-preview-input-upload-hover-color: @green-medium;
@file-preview-no-files-color: #ddd;

.file-preview-container {
  .file-preview {
    padding: 10px;

    .file-preview-thumbnail {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      color: @file-preview-thumbnail-color;
      background-color: @file-preview-thumbnail-bg;
      margin-right: 10px;
    }
  }

  .file-preview-input {
    display: none;
  }

  .file-preview-input-upload {
    color: @file-preview-input-upload-color;
    margin-top: 10px;
    padding: 0 13px;

    &:hover {
      color: @file-preview-input-upload-hover-color;
    }
  }
}

.file-preview-no-files {
  margin-top: 48px;
  font-weight: bold;
  background-color: @file-preview-no-files-color;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 200px;
  text-align: center;
}
