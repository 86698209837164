@import 'Env';

@text-input-border: #bbb;
@text-input-with-error-border: #ff3823;

.text-input-container {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
}

.text-input {
  padding: 10px;
  border: 1px solid @text-input-border;

  &:focus {
    outline: none;
  }
}

.text-input-with-error {
  border-color: transparent;
  border: 1px solid @text-input-with-error-border;
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .text-input {
    font-size: 16px;
  }
}
