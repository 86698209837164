.existing-body {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .blocked-ppt-from-other-studies-alert {
    margin-bottom: 20px;
  }

  .title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .info {
    margin-top: 10px;
  }

  .action-buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
