@import 'Colors';

@action-li-bg: @card-default-bg; // #fff;

@action-li-left-bar-bg: @green-medium;
@action-li-left-top-color: #333;
@action-li-left-bottom-color: @gray-dark;
@action-li-right-top-color: @green-medium;
@action-li-button-color: @green-medium;
@action-li-button-disabled-color: rgba(73, 146, 151, 0.2);
@action-li-show-more-color: @gray-medium;

.action-li {
  display: flex;
  background: @action-li-bg;
  border-radius: 10px;
  box-shadow: @shadow-default;
  margin: 0 28px 19px;
  padding: 19px 0;

  &:first-child {
    margin-top: 34px;
  }

  .action-li-left-bar {
    background: @action-li-left-bar-bg;
    border-radius: 2px;
    width: 3px;
    margin-left: 28px;
  }

  .action-li-left {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    flex: 1;

    .action-li-left-top {
      font-family: Lora;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 10px;
    }

    .action-li-left-bottom {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: @action-li-left-bottom-color;
      padding-bottom: 10px;
    }
  }

  .action-li-right {
    display: flex;
    flex-direction: column;
    margin-right: 28px;

    .action-li-right-top {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: @action-li-right-top-color;
      margin-bottom: 10px;
    }

    .action-li-buttons-container {
      max-height: 100000px;
      display: flex;

      &.collapsed {
        .action-li-button {
          margin-right: 0 !important;
          padding-right: 0;
        }

        .action-li-button-secondary {
          padding-right: 12px;
        }
      }

      .action-li-button {
        height: 17px;
        padding: 14px 12px;
        text-align: center;

        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        text-transform: uppercase;
        color: @action-li-button-color;
        cursor: pointer;
        align-items: flex-end;
        justify-content: flex-end;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &.action-li-button-disabled {
          color: @action-li-button-disabled-color;
          cursor: default;
        }

        &.action-li-button-secondary {
          border-color: @green-medium;
          border-width: 1px;
          border-style: solid;
        }

        &.collapsed {
          display: none;
        }
      }

      @media screen and (min-width: 500px) {
        .action-li-button {
          text-align: right;
          margin-bottom: 0;
        }
      }
    }

    .action-li-show-more {
      #font.body-medium();
      font-weight: bold;
      color: @action-li-show-more-color;
      cursor: pointer;
      margin-top: 10px;
      text-align: right;
    }
  }
}

@media screen and (max-width: 500px) {
  .action-li {
    flex-direction: column;
  }
  .action-li-left {
    margin-right: 18px;
  }
  .action-li-buttons-container {
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    flex-basis: 100%;
    width: 100%;
    .action-li-button {
      width: 100%;

      &:not(:last-child) {
        margin-right: 0px;
      }
    }
  }
  .action-li-right {
    margin-top: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 18px;
  }
}

@media screen and (min-width: 500px) {
  .action-li {
    flex-direction: row;
  }
  .action-li-left {
    margin-right: 0px;
  }
  .action-li-buttons-container {
    flex-direction: row;
  }
  .action-li-right {
    margin-left: 60px;
    align-items: flex-end;
    justify-content: center;
  }
}
