@import 'Env';

@scheduler-day-bg: @white;
@scheduler-day-color: #333;
@scheduler-slots-bg: #edf4f5;
@scheduler-slots-color: #666;
@scheduler-slots-confirm-bg: rgb(75, 145, 151);
@scheduler-slots-confirm-color: @white;

.scheduler {
  display: flex;
  flex-direction: column;
  margin-top: 18px;

  .scheduler-day {
    background: @scheduler-day-bg;
    box-shadow: @shadow-high;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    #font.body-medium();
    font-weight: bold;
    line-height: 20px;
    color: @scheduler-day-color;
    margin-bottom: 18px;

    .scheduler-day-top {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      padding: 18px;
      z-index: 2;

      .scheduler-day-top-left {
        flex: 1;
      }

      .scheduler-day-top-right {
        .scheduler-day-expander-icon {
          transition: transform 0.5s;
          &.inverted {
            transform: rotate(180deg);
          }
        }
      }
    }

    .scheduler-day-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow: hidden;
      max-height: 100000px;
      transition: max-height 1s ease-in-out;
      padding-left: 18px;
      padding-right: 18px;
      padding-bottom: 18px;
      z-index: 1;

      &.collapsed {
        max-height: 0;
        transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
      }

      .scheduler-slots-row {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-top: 18px;

        .scheduler-slot {
          display: flex;
          background: @scheduler-slots-bg;
          border-radius: 5px;
          line-height: 20px;
          color: @scheduler-slots-color;
          padding: 18px;
          flex: 1;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.slot-is-placeholder {
            opacity: 0;
          }

          &.confirm-slot {
            background: @scheduler-slots-confirm-bg;
            color: @scheduler-slots-confirm-color;
          }

          &.back-to-calendar {
            flex: 1;
          }

          &.date {
            flex: 4;
          }

          &:not(:last-child) {
            margin-right: 18px;
          }
        }
      }
    }
  }
}

//stole this from .df-page
.scheduler-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
  max-width: 520px;
  justify-content: center;
}
