.horizontal-slider-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
}
.horizontal-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.value-label-wrapper {
    display: flex;
    flex-direction: column;
    white-space: pre-line;
    text-align: center;
    position: relative;
}
.value-label-wrapper .main-label {
    font-weight: 600;
    position: absolute;
    width: 100%;
    bottom: 100%;
}
.slider-value-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid rgba(12, 12, 13, 0.32);
    width: 6.5rem;
    height: 3.25rem;
    color: rgba(12, 12, 13, 0.88);
}
.slider-min-label, .slider-max-label{
    color: rgba(12, 12, 13, 0.72);
}
.horizontal-slider-wrapper .slider-min-label {
    margin-right: 1rem;
    margin-bottom: 1.375rem;
    text-align: center;
    white-space: pre-line;
}
.horizontal-slider-wrapper .slider-max-label {
    margin-left: 1rem;
    margin-bottom: 1.375rem;
    text-align: center;
    white-space: pre-line;
}
/* Vertical slider properties  */
.vertical-slider-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 16rem;
    padding: 1.5rem;
    justify-content: center;
}
.vertical-slider {
    display: flex;
    flex-direction: column-reverse;
    align-self: stretch;
    align-items: center;
}
.vertical-slider-wrapper .value-label-wrapper {
    margin-right: 2rem;
}
.vertical-slider-wrapper .slider-max-label {
    margin-bottom: .75rem;
    margin-right: 20px;
    text-align: center;
    white-space: pre-line;
}
.vertical-slider-wrapper .slider-min-label {
    margin-top: .75rem;
    margin-right: 20px;
    text-align: center;
    white-space: pre-line;
}

.vertical-overrides {
    margin-left: .75rem;
}
.vertical-overrides > input[type="range"] {
    -webkit-appearance: 'slider-vertical';
}
