@import 'Env';

// Try not to use these without a mixin
@headingFont: 'Lora', serif;
@bodyFont: 'Lato', sans-serif;
@wordmarkFont: 'Comfortaa', sans-serif;

@font-input-color: @green-dark;
@font-input-placeholder-color: rgba(29, 94, 99, 0.2);

#font() {
  .heading-1 {
    font-family: @headingFont;
    font-weight: bold;
    font-size: 30px;
  }

  .heading-2 {
    font-family: @headingFont;
    font-weight: bold;
    font-size: 28px;
  }

  .heading-3 {
    font-family: @headingFont;
    font-weight: bold;
    font-size: 18px;
  }

  .heading-4 {
    font-family: @bodyFont;
    font-weight: 700;
    font-size: 22px;
  }

  .body-large {
    font-family: @bodyFont;
    font-size: 16px;
    font-weight: normal;
  }

  .body-medium {
    font-family: @bodyFont;
    font-size: 14px;
    font-weight: normal;
  }

  .body-small {
    font-family: @bodyFont;
    font-size: 12px;
    font-weight: normal;
  }

  .label {
    font-family: @bodyFont;
    font-weight: bold;
    font-size: 14px;
  }

  .button {
    font-family: @bodyFont;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
  }

  .input {
    font-family: @bodyFont;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: @font-input-color;

    &::placeholder {
      color: @font-input-placeholder-color;
    }
  }

  .wordmark {
    font-family: @wordmarkFont;
  }

  .disclaimer {
    font-family: @bodyFont;
    font-size: 10px;
    line-height: 14px;
  }
}
