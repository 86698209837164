@import 'Fonts';
@import 'Env';
@import 'Colors';

@fr-enrollment-left-color: #929292;
@fr-enrollment-left-color-red-color: @pink;

@fr-data-section-highlight-hover-bg: #dff3fd;
@fr-data-section-active-bg: #dff3fd;
@fr-item-selected-bg: #dff3fd;

@fr-query-section-bg: #dff3fd;

@fr-query-message-content-border: #000;

@keyframes backgroundFadeFromBlue {
  0% {
    background-color: @fr-data-section-highlight-hover-bg;
  }
  100% {
    background-color: transparent;
  }
}

.dc-selected {
  animation: 3s ease-out 0s 1 backgroundFadeFromBlue;
}

.data-capture-results,
.enrollment-results {
  display: flex;

  .dc-divider {
    margin: 15px 0;
  }

  .dc-left,
  .enrollment-left {
    margin-right: 38px;
    text-align: right;
    color: @fr-enrollment-left-color;
    font-weight: bold;
    font-size: 15px;
    min-width: 244px;
    &.red {
      color: @fr-enrollment-left-color-red-color;
    }
  }

  .dc-right,
  .enrollment.right {
    flex: 1;

    .dc-cri {
      margin-bottom: 11px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .dc-cri-title-section {
        display: flex;
        flex-direction: row;

        .dc-cri-title {
          font-weight: bold;
          flex-direction: column;

          .dc-cri-subtitle {
            padding-top: 30px;
            font-size: 15px;
            font-weight: normal;
            font-style: italic;
          }
        }

        .dc-cri-title-sdv-status {
          margin-left: 10px;
        }
      }

      .dc-cri-actions {
        display: flex;
        flex-direction: row;
      }
    }

    .dc-knowledge-content {
      margin-top: 30px;
      margin-bottom: 30px;
      border: 1px solid darkgrey;
      background: lightgrey;
      padding: 10px;
    }
  }

  &:not(:first-child) {
    margin-top: 38px;
  }
}

.form-results {
  margin-bottom: 32px;
}

.fr-data-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.fr-data-section-highlight:hover {
  background-color: @fr-data-section-highlight-hover-bg;

  .fr-data-button {
    visibility: visible;
  }
}

.fr-data-section-active {
  background-color: @fr-data-section-active-bg;
}

.fr-data-button {
  visibility: hidden;
}

.fr-data-button-active {
  visibility: visible;
}

.fr-item {
  &:not(:first-child) {
    margin-top: 28px;
  }
  padding: 10px;
}

.fr-item-selected {
  background-color: @fr-item-selected-bg;
}

.fr-item-question {
  font-weight: 500;
  font-size: 17px;
}

.fr-item-value {
  margin-top: 10px;
}

.fr-query-container {
  display: flex;
}

.fr-query-section {
  margin-top: 20px;
  margin-right: 140px;
  margin-bottom: 20px;
  flex-grow: 1;
  padding: 5px 20px 20px 20px;
  background-color: @fr-query-section-bg;
}

.fr-query-section-open {
  background-color: @fr-query-section-bg;
}

.fr-query-message-header {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.fr-query-message-content {
  padding-left: 15px;
  margin-left: 10px;
  border-left: 2px solid @fr-query-message-content-border;
  padding-bottom: 5px;
}

.fr-query-buttons {
  padding-top: 10px;
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .data-capture-results,
  .enrollment-results {
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 50px;
    }

    .dc-left,
    .enrollment-left {
      text-align: left;
      margin-bottom: 8px;
    }
  }
}
.signature-image {
  max-width: 100%;
  min-width: 300px;
  height: auto;
}
