@import 'Fonts';
@import 'Env';

@patient-mode-indicator-inner-color: @white;
@patient-mode-indicator-inner-bg: #333;

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.page-header {
  display: flex;
  flex-direction: row;
  padding: 28px;

  .ph-left {
    display: flex;
    flex: 1;
    flex-direction: column;

    .ph-left-line-1 {
      display: flex;
      align-items: center;

      .ph-logo {
        margin-left: -6px;
        width: 170px;
        cursor: pointer;
      }
    }
  }

  .ph-right {
    display: flex;
    flex-direction: row;
    justify-content: right;

    .ph-right-trial-info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 10px;

      .trial-name {
        font-family: 'Lora', serif;
        @media screen and (max-width: 1000px) {
          font-size: 3vw;
        }

        @media screen and (min-width: 1000px) {
          font-size: 27px;
        }
      }

      .short-description {
        font-family: 'Lato', sans-serif;
        @media screen and (max-width: 1000px) {
          font-size: 1.6vw;
        }

        @media screen and (min-width: 1000px) {
          font-size: 14px;
        }
      }
    }

    .ph-right-menu-icon-container {
      display: flex;
      padding: 6px;
      align-items: center;
      justify-content: center;
      &:last-child {
        padding-right: 0px;
      }
      cursor: pointer;
    }
  }
}

.patient-mode-indicator-container {
  display: flex;
  align-items: center;
  margin-left: 28px;

  .patient-mode-indicator-inner-container {
    display: flex;
    #font.label();
    padding: 6px 18px;
    align-items: center;
    color: @patient-mode-indicator-inner-color;
    background-color: @patient-mode-indicator-inner-bg;
    border-radius: 8px;
  }
}
