.autocomplete {
  div {
    div::before,
    div::after,
    div:hover:not(.Mui-disabled):before {
      border: none;
    }

    div {
      margin: 0;
      padding: 0 !important;
    }

    input {
      margin: 0;
      &::placeholder {
        opacity: unset;
      }
    }
  }
}
