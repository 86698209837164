@import 'Colors';

@section-bg: @white;
@section-no-padding-bg: @white;
@data-field-border: gray;
@qa-approval-required-color: @status-color-yellow-text; // #ffa834;
@qa-approval-color: @status-color-green-text; // #84b93c;
@duplicate-slug-color: @status-color-red-text; // #ff3823;
@df-regex-test-validator-bg: #efefef;

.section {
  background-color: @section-bg;
  box-shadow: @shadow-default;
  border-radius: 2px;
  margin: 25px 50px;
  padding: 20px;

  &.study-plan {
    overflow-x: scroll;
  }
}

.section-no-padding {
  background-color: @section-no-padding-bg;
  box-shadow: @shadow-default;
  border-radius: 2px;
  margin: 25px 50px;

  &.study-plan {
    overflow-x: scroll;
  }
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  & > button {
    margin-right: 10px;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.expanders {
  margin: 20px -20px;
  padding-left: 20px;
  .spv-epoch-group-title {
    background: #f3f4f5;
    margin-left: -10px;
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 10px;
    width: 99%;
    font-weight: bold;
  }
}

.data-field {
  margin: 0 50px;
  padding: 30px 0;
  border-bottom: 1px solid @data-field-border;
}

.fork-config-dialog-info,
.edit-data-dialog-computer {
  padding: 10px 30px 10px 30px;
}

.compensation-row {
  margin-bottom: 20px;

  .compensation-group {
    display: inline-block;
    vertical-align: middle;

    & > * {
      margin-right: 40px;
    }
  }
}

.change-management-header-left {
  margin-right: 10px;
}

.change-management-header-right {
  display: flex;
  align-items: center;
}

.qa-approval-required {
  color: @qa-approval-required-color;
  svg {
    margin-right: 0.2em;
  }
}

.qa-approval {
  color: @qa-approval-color;
  svg {
    margin-right: 0.2em;
  }
}

.duplicate-slug {
  margin-top: 20px;
  font-size: 13px;
  margin-top: 7px;
  color: @duplicate-slug-color;
}

.trial-builder-header-chip {
  margin-left: 16px;
}

.trial-builder-header {
  align-items: center;
}

.tb-deploy-panel {
  .tb-panel-margin-left {
    margin-right: 50px;
  }

  .tb-buttons-container {
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .tb-group-buttons {
    margin-right: 20px;
  }
}

.tb-deploy-mgmt {
  .mgmt-history-margin-top {
    margin-top: 24px;
  }

  .mgmt-history-btns-margin-top {
    margin-top: 6px;
  }

  .mgmt-history-btns > button {
    margin-right: 12px;
  }

  .mgmt-buttons-deploy {
    margin-left: 20px;
  }
}

.tb-version .tb-version-label {
  margin-right: 4px;
}

.tb-version {
  margin-top: 10px;
  margin-bottom: 40px;
}

.study-plan-table {
  height: 800px;
  max-height: 80vh;
  overflow-x: scroll;
}

.study-plan {
  padding: 0;

  .study-plan-title {
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.study-plan-header-col {
  display: block;
  width: 200px;
  min-width: 100%;
}

.study-plan-header-left-col {
  display: block;
  width: 200px;
}

.tb-buttons-edit-icon {
  margin-right: 10px;
}

.study-plan-header-row {
  z-index: 3;
  position: relative;
}

.df-validator {
  margin-top: 30px;
}

.df-validator-container {
  display: flex;
  flex-direction: column;
}

.df-validator-content {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.df-regex-test-validator {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: @df-regex-test-validator-bg;
  padding: 0 20px;
  margin-top: 20px;
}

.tb-content-tab {
  padding-bottom: 60px;
}

.tb-revision-summary-loading,
.tb-revision-summary--long-file,
.tb-revision-summary--version-one {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data-field .language-picker {
  margin-bottom: 20px;
}
