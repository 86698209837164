@import 'Fonts';
@import 'Colors';

@blockquote-bg: @white;
@blockquote-color: #666;
@blockquote-border-color: #ffb040;
@blockquote-shadow: 2px 2px 15px @default-shadow-color;
@blockquote-before-color: #999;
@blockquote-link-bg: #eee;
@blockquote-link-color: #c76c0c;
@blockquote-link-hover-color: #666;

blockquote {
  display: block;
  background: @blockquote-bg;
  padding: 15px 20px 15px 45px;
  margin: 0 0 20px;
  position: relative;

  /*Font*/
  font-size: 16px;
  line-height: 1.2;
  color: @blockquote-color;
  text-align: justify;

  /*Borders - (Optional)*/
  border-left: 15px solid @blockquote-border-color;
  border-right: 2px solid @blockquote-border-color;

  /*Box Shadow - (Optional)*/
  -moz-box-shadow: @blockquote-shadow;
  -webkit-box-shadow: @blockquote-shadow;
  box-shadow: @blockquote-shadow;

  margin-bottom: 48px;
}

blockquote::before {
  content: '\201C'; /*Unicode for Left Double Quote*/

  /*Font*/
  font-family: Georgia, serif;
  font-size: 60px;
  font-weight: bold;
  color: @blockquote-before-color;

  /*Positioning*/
  position: absolute;
  left: 10px;
  top: 5px;
}

blockquote::after {
  /*Reset to make sure*/
  content: '';
}

blockquote a {
  text-decoration: none;
  background: @blockquote-link-bg;
  cursor: pointer;
  padding: 0 3px;
  color: @blockquote-link-color;
}

blockquote a:hover {
  color: @blockquote-link-hover-color;
}

blockquote em {
  font-style: italic;
}
