@import 'Colors';

.page-footer {
  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }

  font-size: 14px;

  span,
  a {
    color: @gray-dark;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  nav,
  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  ul {
    list-style: none;
    padding-left: 28px;
    padding-right: 28px;

    li {
      padding-left: 15px;
    }
  }
}
