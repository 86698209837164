@import 'Colors';

@test-mode-indicator-color: hsla(0, 0%, 63.1%, 0.44);
@test-mode-indicator-bg-0-bg: @pink;
@test-mode-indicator-bg-50-bg: #f1533f;
@test-mode-indicator-bg-100-bg: @pink;
@test-mode-indicator-subtitle-color: #ffe3ae;

.test-mode-indicator {
  box-shadow: @shadow-default;
  align-items: center;
  border-radius: 5px;
  color: @test-mode-indicator-color;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 8px 16px;
  margin-left: 17px;
  animation-name: bgcolor;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes bgcolor {
    0% {
      background-color: @test-mode-indicator-bg-0-bg;
    }
    50% {
      background-color: @test-mode-indicator-bg-50-bg;
    }
    100% {
      background-color: @test-mode-indicator-bg-100-bg;
    }
  }

  .test-mode-indicator-subtitle {
    font-size: 14px;
    color: @test-mode-indicator-subtitle-color;
  }
}
