@import 'Fonts';
@import 'Colors';

@input-label-color: @gray-medium;
@input-text-bg: @white;
@input-text-border: #e1e1e1;
@input-text-focus-shadow: rgba(62, 62, 62, 0.1);

@multiple-choice-container-color: #333;
@multiple-choice-container-bg: @white;
@multiple-choice-container-border: #e1e1e1;

@multiple-choice-container-active-border: @green-medium;

@multiple-choice-input-border: #999;
@multiple-choice-input-bg: @white;

@radio-input: #ccc;
@radio-input-checked-border: @green-medium;

@checkbox-input-bg: @green-medium;
@checkbox-input-border: @green-medium;
@checkbox-input-before-color: @white;

@btn-primary-bg: @green-medium;
@btn-primary-color: @white;
@btn-primary-hover-bg: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  @green-medium;
@btn-primary-active-bg: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ),
  @green-medium;

@btn-secondary-bg: @pink;
@btn-secondary-color: @white;
@btn-secondary-hover-bg: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  @pink;
@btn-secondary-active-bg: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ),
  @pink;

@btn-back-color: #333;
@btn-back-hover-border: #e8e8e8;
@btn-back-hover-shadow: @shadow-default;
@btn-back-active-bg: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ),
  #ffffff;
@btn-back-active-shadow: @shadow-default;

#input() {
  .label {
    #font.label();
    color: @input-label-color;
    margin-bottom: 8px;
  }

  .text {
    #font.input();
    border-radius: 5px;
    box-shadow: @shadow-default;
    -moz-box-shadow: @shadow-default;
    -webkit-box-shadow: @shadow-default;
    background: @input-text-bg;
    border: 1px solid @input-text-border;
    padding: 10px;
    height: initial; // override for react-phone-number

    &:focus {
      border: 1px solid @green-medium;
      box-sizing: border-box;
      box-shadow: @shadow-high;
      -moz-box-shadow: @shadow-high;
      -webkit-box-shadow: @shadow-high;
    }
  }

  .multiple-choice-container {
    color: @multiple-choice-container-color;
    border-radius: 5px;
    box-shadow: @shadow-default;
    -moz-box-shadow: @shadow-default;
    -webkit-box-shadow: @shadow-default;
    background: @multiple-choice-container-bg;
    border: 1px solid @multiple-choice-container-border;
    padding: 10px;

    &.active {
      border: 1px solid @multiple-choice-container-active-border;
      box-sizing: border-box;
      box-shadow: @shadow-high;
      -moz-box-shadow: @shadow-high;
      -webkit-box-shadow: @shadow-high;
    }

    &:hover,
    *:hover {
      cursor: pointer;
    }
  }

  .multiple-choice-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid @multiple-choice-input-border;
    width: 20px;
    height: 20px;
    background-color: @multiple-choice-input-bg;

    &:focus {
      outline: none;
    }
  }

  .radio-input {
    #input.multiple-choice-input();
    border: 1px solid @radio-input;
    border-radius: 50%;

    &:checked {
      border: 6px solid @radio-input-checked-border;
    }
  }

  .checkbox-input {
    #input.multiple-choice-input();

    &:checked {
      background-color: @checkbox-input-bg;
      border-color: @checkbox-input-border;

      &:before {
        content: '\2713\0020';
        color: @checkbox-input-before-color;
        font-size: 18px;
        line-height: 1em;
      }
    }
  }
}

#button() {
  .primary {
    #font.button();
    text-align: center;
    border-radius: 5px;
    padding: 20px;
    min-width: 180px;
    //    background: @btn-primary-bg;
    //    color: @btn-primary-color;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 5px;
      height: 18px;
    }

    &:hover {
      cursor: pointer;
      //      background: @btn-primary-hover-bg;
    }

    &:active {
      //      background: @btn-primary-active-bg;
    }
  }

  .secondary {
    #font.button();
    text-align: center;
    border-radius: 5px;
    padding: 20px;
    min-width: 180px;
    //    background: @btn-secondary-bg;
    //    color: @btn-secondary-color;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 17px;
      margin-right: 5px;
    }

    &:hover {
      cursor: pointer;
      //      background: @btn-secondary-hover-bg;
    }

    &:active {
      //      background: @btn-secondary-active-bg;
    }
  }

  .back {
    #font.button();
    text-align: center;
    margin-right: 10px;
    border-radius: 5px;
    padding: 20px;
    color: @btn-back-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    svg {
      height: 18px;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid @btn-back-hover-border;
      box-shadow: @shadow-default;
    }

    &:active {
      background: @btn-back-active-bg;
      box-shadow: @shadow-default;
    }
  }
}
