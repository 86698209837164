.download-progress-card {
  margin: 15px 28px 25px;
  border-radius: 2px;
  box-shadow: @shadow-high;

  background-color: #ffffff;
  padding: 20px 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  max-height: 100px;
  opacity: 1;

  transition: opacity 200ms 200ms, max-height 200ms, margin 200ms, padding 200ms;

  &.hidden {
    transition: opacity 0.5s, max-height 200ms 1s, margin 200ms 1s,
      padding 200ms 1s;

    opacity: 0;
    margin: 0;
    padding: 0;
    max-height: 0;
    overflow: hidden;
  }

  .title {
    margin-bottom: 8px;
  }

  .subtitle {
    margin-bottom: 16px;
  }

  .left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
