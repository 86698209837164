@report-new-event-main-item-btn-color: #000;
@report-new-event-main-item-btn-bg: @white;

@ae-background-bg: @white;
@ae-background-border: #bbb;

@medical-problem-background-bg: @white;
@patient-picker-subheader-bg: @white;
@patient-picker-subheader-border: #bbb;

.report-new-event {
  margin-left: 28px;
}

.report-new-event-main {
  display: flex;
  flex-wrap: wrap;
  padding-top: -10px;

  .item {
    padding: 5px;
    text-align: center;

    > button {
      display: block;
      border-radius: 10px;
      width: 100px;
      height: 100px;
      font-size: 10px;
      font-size: calc(90%);
      color: @report-new-event-main-item-btn-color;
      background: @report-new-event-main-item-btn-bg;
      float: left;
      box-shadow: @shadow-high;
    }

    .btn-text {
      #font.body-medium();
    }
  }
}
.ae-background {
  background-color: @ae-background-bg;
  border-bottom: 1px solid @ae-background-border;
  border-top: 1px solid @ae-background-border;

  .custom-dynamic-form-submit-button-signature {
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 30px;
    max-width: 800px;

    > button {
      margin-top: 10px;
      max-width: 800px;
    }
  }
}

.medical-problem-background {
  background-color: @medical-problem-background-bg;
}

.patient-picker-subheader {
  background: @patient-picker-subheader-bg;
  border-style: solid;
  border-color: @patient-picker-subheader-border;
  border-width: 0px;
  border-bottom-width: 1px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 21px 28px;
  font-size: 18px;
  border-width: 0px;
  flex: 1;

  &:focus {
    outline: none;
  }
  border-bottom: 0.5px solid @patient-picker-subheader-border;
}
