@import 'Colors';

#cconsent-bar {
  z-index: 999999 !important;
  padding: 30px !important;

  @media (min-width: 768px) {
    left: 30px !important;
    right: 30px !important;
    width: auto !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
      0px 5px 10px rgba(0, 0, 0, 0.28);
  }

  .cc-text {
    color: @black !important;
  }
}
