@import 'FormControls';
@import 'Fonts';
@import 'Colors';

@booker-title-color: @green-dark;
@booker-text-color: @green-medium; // #1d5e63;
@booker-back-button-color: @gray-medium;
@loading-message-color: @green-dark;

.explore {
  .booker {
    @media screen and (max-width: 599px) {
      z-index: 9997; // it cannot be higher than the sticky header in mobile view.
    }
  }
}

.booker {
  display: flex;
  flex-direction: column;
  z-index: 999999999999999999; // Even higher than material UI dialogs
  flex: 1;

  @media screen and (max-width: 599px) {
    width: 100vw;
    height: calc(
      100vh - 72px
    ); // Fallback where the webkit function is not supported
    height: -webkit-fill-available; // Optimizes for actual available screen real estate
  }

  .close-dialog-icon-container {
    position: absolute;
    top: 30px;
    right: 28px;
    cursor: pointer;

    .close-dialog-icon {
      width: 16px;
      height: 16px;
    }
  }

  &.booker-loading {
    align-items: center;
  }

  .booker-text-container {
    margin: 28px;
    @media screen and (max-width: 599px) {
      width: calc(100vw - 56px);
    }
  }

  .booker-title {
    #font.heading-1();
    line-height: 33px;
    color: @booker-title-color;
    border-radius: 10px;
    margin-bottom: 18px;
    margin-right: 60px; // To ensurer we don't collide with the close icon
  }

  .booker-text {
    #font.body-medium();
    line-height: 24px;
    color: @booker-text-color;
    border-radius: 10px;
    margin-bottom: 18px;
    &.error {
      color: @status-color-red-text;
    }
  }

  .booker-back-button {
    #font.body-medium();
    font-weight: bold;
    line-height: 16px;
    color: @booker-back-button-color;
    cursor: pointer;
    margin-bottom: 18px;
  }

  .booker-button-bar {
    display: flex;
    flex-direction: row;
    padding: 18px;
  }

  .booker-input {
    #input.text();
  }

  .booker-button {
    #button.primary();

    &.full-width {
      flex: 1;
    }
    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  .booker-text-input-container {
    margin-top: 18px;
  }

  .booker-button-container {
    margin-top: 18px;
  }

  .booker-ocp-container {
    margin-top: 28px;
  }

  .booker-card-roll-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  .booker-map-container {
    position: relative;

    @media screen and (max-height: 800px) {
      margin-top: -20px;
    }

    @media screen and (max-width: 600px) {
      width: 100vw;
      flex: 1;
    }

    @media screen and (min-width: 600px) {
      width: 100%;
      height: 400px;
    }

    .hideMap {
      visibility: hidden;
    }
  }

  .day-outside-window {
    .MuiPickersDay-day {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  .booker-confirm-actions {
    display: flex;
    margin: 20px 0;
    flex-direction: row-reverse;
  }

  .booker-confirm-outside-window {
    margin: 20px 0;
    /* Label / Small */

    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 0.1px;

    /* Utility/Info/Info-main */
    color: #1d6195;

    .outside-window-title {
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  .booker-loading-message {
    color: @loading-message-color;
  }
}

// Remove Google Maps Logos and Boilerplate
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
