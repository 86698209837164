@import 'Fonts';

.confirmation-dialog-message {
  margin: 28px;
}

.confirmation-dialog-message-innerhtml {
  img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 80%;
  }
}

.confirmation-dialog-message-innerform {
  padding-bottom: 0px;
}

.confirmation-dialog-buttons {
  padding: 18px;
}

.confirmation-dialog-buttons-center {
  padding: 18px;

  button {
    width: 100%;
  }
}
