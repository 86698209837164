@import 'Fonts';
@import 'Env';

@legal-document-page-bg: #eee;
@legal-document-container-bg: @white;
@support-container-color: #434343;

.legal-document-page {
  background-color: @legal-document-page-bg;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.legal-document-container {
  background-color: @legal-document-container-bg;
}

.legal-document-accept-button {
  max-width: 300px;
  margin-top: 34px;
}

.scroll-button-container {
  text-align: right;
  button {
    margin-left: 10px;
  }
}

.support-container {
  font-size: 18px;
  color: @support-container-color;
  margin: 0 0 15px;

  .support-text {
    margin-right: 15px;
  }
}

@media screen and (min-width: @isTabletSizedMaxWidth) {
  .legal-document-container {
    margin: 100px;
    padding: 40px;
  }
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .legal-document-container {
    margin: 0px;
    padding: 20px;
  }
  .legal-document-accept-button {
    margin-bottom: 80px;
  }
}
