@import 'Fonts';
@import 'Env';
@import 'Colors';

// Manual override for Safari-only bug
// https://www.ryadel.com/en/css3-media-query-target-only-ie-ie6-ie11-firefox-chrome-safari-edge/
// _::-webkit-full-page-media, _:future, :root .auth-page .auth-container .auth-container-right {
//   display: block;
// }

@auth-page-bg: #333;
@auth-container-bg: @white;
@auth-container-left-bg: @blue-darker;
@auth-container-left-color: @white;

.auth-page {
  background-color: @auth-page-bg;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  .auth-container {
    background-color: @auth-container-bg;
    display: flex;
    flex-direction: row;
    flex: 1;
    max-width: 764px;
    min-height: 460px;
    -moz-box-shadow: @shadow-high;
    -webkit-box-shadow: @shadow-high;
    box-shadow: @shadow-high;

    .auth-container-left {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: @auth-container-left-bg;
      padding: 28px;

      .powered-by {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          display: inline-block;
          margin-right: 10px;
        }

        span:first-child {
          font-size: 12px;
        }

        span:last-child {
          #font.wordmark();
          font-size: 22px;
        }
      }

      .auth-container-left-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .auth-container-left-p-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
      }

      .auth-container-left-title {
        text-align: left;
        color: @auth-container-left-color;
        background-color: @auth-container-left-bg;
        font-size: 36px;

        #font.wordmark();
      }

      .auth-container-left-indicator {
        padding-left: 8px;
      }

      .auth-container-left-language {
        color: @auth-container-left-color;
      }

      .auth-container-left-p {
        color: @auth-container-left-color;
        margin-bottom: 28px;
      }
    }

    .auth-right-header-inputs {
      padding: 0px 20px;
      .field-label {
        #font.body-medium();
        font-weight: bold;
      }
      .field-error {
        .status-color-red;
        margin-top: 10px;
        #font.body-small();
      }
      .text-input-container {
        margin-top: 10px;
      }
    }

    .auth-container-right {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 333px;

      .button {
        margin-top: 15px;
      }

      .auth-right-header {
        #font.heading-2();
        margin: 26px 20px 10px;

        .status-text-container {
          #font.body-medium();
        }
      }

      .auth-right-body {
        margin: 20px;
      }

      .auth-right-text {
        font-size: 13px;
        a {
          text-decoration: none;
          color: @black;
        }
      }

      .auth-right-link {
        font-size: 13px;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          color: @auth-container-left-bg;
        }
      }
    }
  }
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .auth-page {
    align-items: unset;
    justify-content: unset;
    height: inherit;

    .auth-container {
      flex-direction: column;

      .auth-container-right {
        max-width: none;
      }
    }
  }

  .auth-container-left {
    max-height: 45px;
  }
}

.auth-login-bottom-row {
  display: flex;
  flex-direction: row;
}

.auth-login-remember-me {
  padding-top: 16px;
  margin-left: -5px;
}

.auth-login-bottom-row-left {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
}

.auth-login-bottom-row-right {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: right;
}

.auth-right-login-bottommost-row {
  margin-top: 48px;
}

@media screen and (max-width: @isExtraSmallMaxWidth) {
  .auth-container-left {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .auth-right-header {
    margin-bottom: 36px;
  }

  .auth-right-header-magic-link {
    margin-bottom: 36px;
  }
}
