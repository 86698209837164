@import 'Helpers';
@import 'ActionCard';
@import 'ActionListItem';
@import 'AdminTools';
@import 'AlertMessage';
@import 'Auth';
@import 'AutocompleteMultipleSelect';
@import 'Autocomplete';
@import 'Blockquotes';
@import 'Booker';
@import 'BottomButtons';
@import 'Bullets';
@import 'CardRoll';
@import 'Chevron';
@import 'ConfirmationDialog';
@import 'CookieConsentBar';
@import 'Consent';
@import 'MarkdownEditorWYSIWYG';
@import 'Dialog';
@import 'DynamicForm';
@import 'EmbeddedScheduler';
@import 'Env';
@import 'ErrorPage';
@import 'Errors';
@import 'Explore';
@import 'FilePreview';
@import 'Fonts';
@import 'FormResults';
@import 'Header';
@import 'ImageGallery';
@import 'InviteUsers';
@import 'KnowledgeContent';
@import 'LeftNavbar';
@import 'Legal';
@import 'LightDialog';
@import 'ListItem';
@import 'ExpansionPanelItem';
@import 'OneClickPicker';
@import 'PageHeader';
@import 'PageFooter';
@import 'Participant';
@import 'ParticipantDashboard';
@import 'ParticipantDataCaptureReview';
@import 'DataCaptureReview';
@import 'PinInput';
@import 'RandomizationBlock';
@import 'ReferralSources';
@import 'Report';
@import 'ReportedEvents';
@import 'Scheduler';
@import 'SearchBar';
@import 'Settings';
@import 'SingleSelect';
@import 'SponsorDashboard';
@import 'StatusItem';
@import 'StatusText';
@import 'Subheader';
@import 'PasswordRequirements';
@import 'PasswordStrength';
@import 'TestModeIndicator';
@import 'TextInput';
@import 'TrialBuilder';
@import 'Tag';
@import 'TrialOption';
@import 'TrialPicker';
@import 'DataCaptureMissed';
@import 'MessageCenterPanel';
@import 'MessageCenterPanelMobile';
@import 'TrialSelector';
@import 'DuplicateEmailWarning';
@import 'MainTelemedRoom';
@import 'TelemedShared';
@import 'GtmContainerAssigner';
@import 'TelehealthFileUpload';
@import 'ThemedDialog';
@import 'CallOverlayPanel';
@import 'DiffViewer';
@import 'RevisionSummary';
@import 'CaseReportSignature';
@import 'StyleBuilder';
@import 'Colors';
@import 'DrugbankAutocomplete';
@import 'HelpContentCard';
@import 'LanguageSelector';
@import 'ExternalSignUpBlock';
@import 'ConfirmReconsentDialog';
@import 'MachineConfigurationDialogue';
@import 'SPVPDFView';
@import 'DownloadProgressCard';

body {
  background-color: @body-bg;
  font-family: @bodyFont;
}

html,
body,
#root {
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

// Zendesk widget override
iframe#launcher {
  bottom: 39px !important;
}

#sticky-footer {
  bottom: 0;
  display: block;
  position: fixed;
  width: 100%;
  z-index: 1000;

  & .df-footer {
    margin: 0;
  }
}

.page-container {
  display: flex;
  flex: 1;
  min-height: 0;
}

.participant-dashboard-container,
.participant-datacapture-review-container {
  background-color: @participant-dashboard-container-color;
  flex: 1;
}

.participant-dashboard,
.participant-datacapture-review,
.telemed-waiting-room {
  max-width: 900px;
  margin: auto;
  overflow-x: hidden;
  z-index: 3;
}

.view-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;

  .telemed-mobile-buffer {
    display: flex;
  }
}

.data-capture,
.informed-consent {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: @informed-consent-bg;
}

.primary-button:not(.ccb__button .primary-button) {
  #button.primary();
}

.secondary-button:not(.ccb__button .secondary-button) {
  #button.secondary();
}

.simple-button {
  color: @simple-button-color;
  width: auto;
  margin-right: 30px;

  &:hover {
    cursor: pointer;
  }
}

.withfadeout {
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  transition: opacity 3s ease-in-out;
}

.cookie-hover {
  position: fixed;
  bottom: 28px;
  right: 28px;
  background-color: @cookie-hover-bg;
  padding: 28px;
  z-index: 100000;
  color: @cookie-hover-color;
  display: flex;
  flex-direction: column;
}

.cookie-header {
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.cookie-header-left {
  flex: 1;
}

.cookie-header-right {
  margin-top: -12px;
  margin-right: -18px;
}

.cookie-body {
  margin-top: 18px;
  font-size: 12px;
  line-height: 20px;

  a {
    color: @cookie-body-link-color;
    font-weight: bold;
  }
}

.icon-medium {
  max-width: 120px;
}

// Used in trial builder for machine editor
.jsoneditor {
  height: 80vh !important;
  border: none !important;

  .jsoneditor-menu {
    background-color: @jsoneditor-menu-bg;
    border-color: @jsoneditor-menu-border;
  }
}

@media screen and (min-width: @isTabletSizedMaxWidth) {
  .cookie-hover {
    width: 360px;
  }
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .cookie-hover {
    width: 240px;
  }
}
