.reconsent-dialog {
  display: flex;
  flex-direction: column;
  .reconsent-dialog-radio-button {
    border: 1px solid #0c0c0d52;
    border-radius: 4px;
    margin-bottom: 0.25rem;
    height: 3rem;
  }
  .reconsent-dialog-radio-label {
    margin-bottom: 0.5rem;
  }
  .reconsent-dialog-header {
    width: 100%;
    background-color: #444444;
    color: #ffffff;
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
  .reconsent-dialog-content {
    padding: 1rem;
    p {
      margin: 0.5rem 0 1rem 0;
    }
  }
}

.reconsent-dialog-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
}
