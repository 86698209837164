@import 'Fonts';
@import 'StatusItem';
@import 'Colors';

@alert-message-inner-cont-color: gray;

.alert-message-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
}

.alert-message-container-visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 600ms;
}

.alert-message-container-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 600ms, opacity 600ms;
}

.alert-message-inner-container {
  margin: 22px;
  background-color: @alert-message-inner-cont-color;
  padding: 12px 18px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  min-width: 200px;
  max-width: 300px;
}

.alert-message-title {
  font-weight: 600;
}

.alert-message-text {
  margin-top: 4px;
}

.alert-message-inner-container-red {
  color: @status-color-red-text;
  background-color: @status-color-red-bg;
  border-color: @status-color-red-text;
}

.alert-message-inner-container-green {
  color: @status-color-green-text;
  background-color: @status-color-green-bg;
  border-color: @status-color-green-text;
}

.alert-message-inner-container-yellow {
  color: @status-color-yellow-text;
  background-color: @status-color-yellow-bg;
  border-color: @status-color-yellow-text;
}

.alert-message-inner-container-gray {
  color: @status-color-gray-text;
  background-color: @status-color-gray-bg;
  border-color: @status-color-gray-text;
}
