@import 'Colors';
@import 'Env';
@import 'Fonts';
@import 'FormControls';
@import 'Signature';

@df-dropdown-item-border: #bbb;
@df-color: @text-color;
@df-context-title: @title-color;
@df-context-estimated-time-color: #b2b2b2;
@df-context-p-color: @title-color;

@df-page-header-color: @title-color;
@df-page-header-error-message-color: #ff3823;

@df-knowledge-content-bg: @status-color-gray-bg;
@df-knowledge-content-color: @status-color-gray-text;
@df-knowledge-content-border: @status-color-gray-text;

@df-element-note-color: #999;
@df-sub-element-input-bg: #eae9e9;
@df-sub-element-input-with-error-border: #ff3823;
@df-sub-element-input-reset-container-hover: @blue-darker;

@df-date-sub-element: @title-color;
@df-multiple-choice-option-active-bg: @green-medium;
@df-multiple-choice-option-active-color: @white;

@df-footer-bg: @white;
@df-footer-shadow: #ccc; // #accace;
@df-footer-affirmation-color: @title-color;

@df-selection-note-span-color: rgb(90, 145, 150);

@df-list-element-item-text: #505050;

@scheduler-buttons-container-color: #f00;

@currency-input-adorment-bg: @white;
@currency-input-adorment-border: #e1e1e1;
@currency-input-adorment-shadow: @shadow-default;
@currency-input-adorment-errors-border: #f00;
@currency-input-adornment-focused-border: @green-medium;
@currency-input-adornment-focused-shadow: rgba(62, 62, 62, 0.1);

// Manual override for Safari-only bug
// https://www.ryadel.com/en/css3-media-query-target-only-ie-ie6-ie11-firefox-chrome-safari-edge/
_::-webkit-full-page-media,
_:future,
:root .material-dialog .df-body {
  display: block;
}

// MaterialUI override
.dropdown-item {
  white-space: normal !important;
  height: 100% !important;
  padding: 20px !important;
}
.dropdown-item ~ .dropdown-item {
  border-top: 1px solid @df-dropdown-item-border !important;
}

.dropdown-placeholder {
  div > div {
    color: @font-input-placeholder-color;
  }
}

.dropdown-placeholder-hide {
  display: none !important;
}

.tos-dialog-content {
  padding: 20px;
  .tos-dialog-close-icon {
    display: flex;
    justify-content: flex-end;
  }
}

.dynamic-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: @df-color;
  font-size: 14px;
}

.df-body {
  display: flex;
  flex: 1;
  justify-content: space-evenly;

  .df-context {
    flex: 1;
    margin: 20px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .df-context-image-container {
      text-align: center;
      margin-bottom: 15px;
      img {
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
      }
    }

    .df-context-title {
      color: @df-context-title;
      #font.heading-1();

      &.form-title {
        margin-bottom: 35px;
      }
    }

    .df-context-estimated-time {
      color: @df-context-estimated-time-color;
      display: flex;
      align-items: center;
      margin-top: 9px;

      svg {
        margin-right: 5px;
      }
    }

    p {
      #font.body-medium();
      margin-bottom: 0;
    }
  }

  .df-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;
    max-width: 520px;
    justify-content: center;
    overflow: hidden;

    @media screen and (max-width: @isExtraSmallMaxWidth) {
      margin: 20px 5px;
    }

    .df-header-text {
      #font.heading-1();
      margin-bottom: 10px;
    }

    .df-header-description {
      margin-bottom: 10px;
    }
    .df-header-divider {
      margin-top: 30px;
    }

    .error-message {
      font-size: 13px;
      margin-top: 7px;
      color: @df-page-header-error-message-color;
    }

    .df-knowledge-content {
      font-size: 14px;
      background-color: @df-knowledge-content-bg;
      color: @df-knowledge-content-color;
      border-radius: 4px;
      border: 1px solid @df-knowledge-content-border;
      padding: 0 18px 4px;
      margin-top: 22px;
      margin-bottom: 18px;

      .kc-title {
        font-weight: 800;
        margin-top: 18px;
      }
    }

    .df-element {
      &:not(:first-child) {
        margin-top: 30px;
      }

      .df-element-title {
        min-height: 5px;
        #input.label();
      }

      .df-element-note {
        color: @df-element-note-color;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.4em;
        margin-bottom: 10px;
      }

      .df-element-note-bullet {
        color: @df-element-note-color;
        font-size: 13px;
        font-weight: bold;
        padding-top: 18px;
        margin-top: -12px;

        &:first-child {
          margin-top: -24px;
        }
      }

      .df-sub-element {
        display: flex;
        flex-direction: column;

        textarea,
        input[type='datetime-local'],
        input[type='text'],
        input[type='number'],
        input[type='tel'],
        input[type='password'] {
          flex: 1;
          #input.text();
          min-width: 46px;
        }

        input {
          &:disabled {
            background-color: @df-sub-element-input-bg;
          }

          &:focus {
            outline: none;
          }

          &.with-error {
            border-color: transparent;
            border: 1px solid @df-sub-element-input-with-error-border;
          }
        }
        .reset-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .df-password-reset {
            margin-top: 10px;
            font-size: 13px;
            font-weight: 600;

            &:hover {
              cursor: pointer;
              color: @df-sub-element-input-reset-container-hover;
            }
          }
        }

        .df-multiple-choice-option {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          #input.multiple-choice-container();
          margin: 10px 0;

          &:hover {
            cursor: pointer;
          }

          input[type='radio'] {
            #input.radio-input();
          }

          input[type='checkbox'] {
            #input.checkbox-input();
          }
        }

        .df-date-sub-element {
          display: flex;
          flex-direction: row;
          align-items: center;

          @media screen and (max-width: 520px) {
            // HACK
            width: calc(100vw - 20px);
          }

          &:not(:first-child) {
            margin-left: 15px;
          }

          .date-divider {
            font-size: 24px;
            margin: 7px;
            color: @df-date-sub-element;
          }
        }

        .df-horizontal-multiple-choice-container {
          display: flex;
          align-items: stretch;

          .df-multiple-choice-option {
            display: flex;
            min-width: 5px;
            flex-direction: column;
            align-items: center;
            flex-wrap: nowrap;
            flex-basis: 50%;
            flex-grow: 1;
            padding: 0;
            border-radius: 0;

            input {
              display: none;
            }

            &.active {
              background-color: @df-multiple-choice-option-active-bg;
              color: @df-multiple-choice-option-active-color;
            }
          }
        }

        .df-horizontal-axis-markers {
          display: flex;
          justify-content: space-between;

          .df-ham-spacer {
            flex: 1;
          }

          .df-ham-left,
          .df-ham-right {
            flex: 3;
          }

          .df-ham-right {
            text-align: right;
          }
        }
      }
    }
    .df-hidden {
      display: none;
    }
  }
}

.affirmation {
  #font.disclaimer();
  color: @df-footer-affirmation-color;
  .signup-affirmation {
    margin-left: -18px;
    margin-top: 10px;
    .signup-tos-text {
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-weight: bold;
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .tos-links {
      color: @df-footer-affirmation-color;
    }
  }
}
.data-capture .df-footer {
  position: fixed;
}

.data-capture .df-page {
  padding-bottom: 20px;
}

.df-footer {
  margin: 25px 0 0;
  padding: 10px;
  box-shadow: 0px -6px 10px -6px @df-footer-shadow;
  background-color: @df-footer-bg;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;

  .df-left-side-footer-slot {
    display: flex;
    align-items: center;
  }

  .df-button-container {
    display: flex;
    @media screen and (max-width: 600px) {
      padding-right: 1rem;
    }

    .forward-button {
      #button.primary();
      min-width: unset;
      flex: 7;
    }

    .backward-button {
      #button.back();
      min-width: unset;
      flex: 1;
    }

    .autocomplete-button {
      #button.secondary();
      min-width: unset;
      margin-left: 10px;
    }

    .skip-button {
      min-width: unset;
      margin-left: 10px;
    }
  }
}

.df-selection-note {
  padding: 5px;

  span {
    color: var(--mq-txt-color, @df-selection-note-span-color);
    font-style: italic;
  }
}

.dynamic-form-date {
  max-width: 200px;
  width: 100%;
}

.dynamic-form-list-element {
  margin: 8px 3px;
  border-radius: 1px;
  border: 1px dashed #000000;
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
}

.dynamic-form-list-element-columns {
  flex-direction: column;
}

.dynamic-form-list-element-delete-button-container {
  border-style: none;
  margin: 0;
}

.dynamic-form-list-element-add-button-container {
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.dynamic-form-list-element-item {
  margin-top: 12px;
  min-width: 50%;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.dynamic-form-list-element-item-text {
  width: 30%;
  font-size: 12px;
  font-weight: 600;
  margin: 3px 15px 3px 3px;
  color: @df-list-element-item-text;
}

.dynamic-form-list-element-item-input {
  width: 70%;
  margin: 2.5px 10px 2.5px 2.5px;
}

.dynamic-forms-multiselect-text {
  padding: 10px;
  display: flex;
  align-items: center;
  flex: 1;

  .inner-text {
    flex: 3;
  }

  .inner-img {
    flex: 1;
    padding: 5px;
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.dynamic-form-dropdown-sub-element {
  margin-top: 14px;
}

.dynamic-form-dropdown-alt {
  flex: 1;
  min-width: 46px;
  #input.text();
  padding: 0;
  &:disabled {
    background-color: @df-sub-element-input-bg;
  }

  &:focus {
    outline: none;
  }

  &.with-error {
    border-color: transparent;
    border: 1px solid @df-sub-element-input-with-error-border;
  }

  div {
    #input.text();
    margin: 0;
    padding: 5px;
    border: none;
    box-shadow: none;
    &:before,
    &:after,
    &:hover:not(.Mui-disabled):before,
    &:focus {
      border: none;
      box-shadow: none;
    }

    &:before,
    &:after,
    &:focus {
      background-color: transparent;
    }

    svg {
      right: 5px;
    }
  }
}

.dynamic-form-checkbox-sub-element {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dynamic-form-checkbox-sub-element-right {
  margin-left: 6px;
  cursor: pointer;
}

.scheduler.li-multi-container.inset {
  margin: 0;

  .li-title,
  .li-subtitle {
    display: inline-block;
  }

  .li-subtitle {
    margin-left: 10px;
    font-size: 16px;
  }

  .li-middle {
    display: flex;

    .li-header {
      flex: 1;
    }

    .li-buttons-container {
      margin: 0;

      button {
        color: @scheduler-buttons-container-color;
      }
    }
  }
}

.react-phone-number-input__icon {
  height: 1em !important;
  border: none !important;
}

.currency {
  position: relative;

  .currency-input {
    display: flex;
    align-items: center;

    .currency-input-field {
      height: 32px !important;
      padding-right: 10px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0px !important;
    }

    .currency-input-field:focus {
      box-sizing: content-box !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .currency-input-adornment {
      background-color: @currency-input-adorment-bg;
      border: 1px solid @currency-input-adorment-border;
      border-left: 0px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      padding: 10px;
      box-shadow: 2px 1px 3px @currency-input-adorment-shadow;
      -moz-box-shadow: 2px 1px 3px @currency-input-adorment-shadow;
      -webkit-box-shadow: 2px 1px 3px @currency-input-adorment-shadow;

      &.with-error {
        border: 1px solid @currency-input-adorment-errors-border;
        border-left: 0;
      }

      .currency-input-adornment-text {
        text-transform: uppercase;
      }
    }

    .currency-input-adornment-focused {
      border: 1px solid @currency-input-adornment-focused-border;
      border-left: 0px;
      box-shadow: 11px 5px 20px @currency-input-adornment-focused-shadow;
      -moz-box-shadow: 11px 5px 20px @currency-input-adornment-focused-shadow;
      -webkit-box-shadow: 11px 5px 20px @currency-input-adornment-focused-shadow;
    }
  }
}

.markdown {
  width: 100%;
  max-width: 100%;
}

.hide-from-roles-content {
  button {
    padding: 8px;
  }
}

.document-sign-toolbar {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .sign-button,
  .next-button,
  .declined-button,
  .autocomplete-button {
    margin-top: 10px;
  }

  @media screen and (max-width: @isExtraSmallMaxWidth) {
    flex-direction: column;

    .sign-button {
      flex: 1;
    }
  }
}

.document-sign-toolbar-autocomplete {
  @media screen and (max-width: @isExtraSmallMaxWidth) {
    display: flex;
  }
}

.dynamic-form-address {
  display: flex;
  flex-direction: column;

  * {
    margin: 0 0 1em 0;
  }

  div:nth-child(4) {
    display: flex;

    div:nth-child(1) {
      margin-right: 0.5em;
    }
    div:nth-child(2) {
      margin-left: 0.5em;
    }
  }
}
