@import 'Fonts';
@import 'Env';
@import 'Colors';

@li-container-border: #bbb;
@li-container-bg: @white;
@li-container-error-color: #f00;
@li-container-error-border: #f00;
@li-middle-subtitle-color: #a4a4a4;
@li-status-text-color: #e84141;
@li-mobile-left-border: #999;
@li-clickable-left-header-color: #00b0ff;
@li-clickable-left-header-hover-bg: #f9f9f9;
@li-clickable-left-header-hover-color: #3c75ff;

.li-multi-container {
  &:last-child {
    border: none;
  }

  .li-container ~ .li-container {
    border-top: none;
  }

  &.inset {
    margin: 15px 28px 25px;
    border-radius: 2px;

    .li-container {
      border: none;
    }

    .li-container ~ .li-container {
      border-top: 1px solid @li-container-border;
    }
  }
  &.without-epoch {
    margin-top: 50px;
  }

  &.no-shadow {
    box-shadow: none;
  }
}

.li-container {
  border-top: 1px solid @li-container-border;
  border-bottom: 1px solid @li-container-border;
  background-color: @li-container-bg;
  padding: 20px 28px;
  display: flex;
  flex-direction: row;

  &.error {
    color: @li-container-error-color;
    border: 2px solid @li-container-error-border;
    .li-title-container {
      color: @li-container-error-color;
    }
  }

  .li-left {
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px;
    flex: 1;

    .li-left-subtitle {
      margin-top: 6px;
      font-style: italic;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .li-middle {
    flex: 11;

    .li-header {
      display: flex;
      align-items: center;

      .li-title-container {
        font-size: 20px;
        margin-bottom: 0px;
        flex: 1;
      }

      .li-tag {
        margin-right: 10px;
      }

      .li-subtitle {
        font-size: 13px;
        font-style: italic;
        color: @li-middle-subtitle-color;
        margin-top: 1px;
      }
    }
  }

  .li-status {
    flex: 1;
    margin-right: 25px;

    .li-status-text {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      margin-top: 5px;
      color: @li-status-text-color;
    }
  }

  .icon-container {
    width: 32px;
    height: 32px;
    margin: 1px auto 0;

    .icon-status {
      font-size: 32px;
    }
  }

  .chip-list-item {
    margin-right: 10px;
  }

  .img-container {
    width: 25px;
    height: 25px;
    margin: 1px auto 0;

    img {
      width: 25px;
      height: 25px;
    }

    &.small {
      width: 20px;
      height: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.mobile {
    .li-left {
      padding-bottom: 18px;
      border-bottom: 1px solid @li-mobile-left-border;
      margin-bottom: 28px;
    }
  }

  &.clickable {
    cursor: pointer;

    .li-header .li-title-container {
      color: @li-clickable-left-header-color;

      span,
      svg {
        vertical-align: middle;
      }

      svg {
        margin-left: 6px;
      }
    }

    &:hover {
      background-color: @li-clickable-left-header-hover-bg;
      .trial-row .non-arrow .participant-name {
        color: @li-clickable-left-header-hover-color;
      }
    }
  }
}

.li-dense {
  font-size: 14px;
  line-height: 21px;
}

.li-no-bullet {
  list-style-type: none;
  padding-inline-start: 0px;
}

.li-text {
  font-size: 14px;
  margin-top: 10px;
  line-height: 21px;
}

.li-followUpText {
  font-size: 14px;
  margin-top: 10px;
  line-height: 21px;
  font-style: italic;
}

.li-buttons-container {
  margin-top: 18px;
  margin-left: -15px;
}

.li-button-right {
  float: right;
}

@media screen and (max-width: @isExtraSmallMaxWidth) {
  .li-middle-top {
    flex-direction: column;
  }
}
