@trial-picker-container-bg: #eee;

.trial-picker-container {
  background-color: @trial-picker-container-bg;
}

.trial-picker-subheader {
  font-weight: bold;
  margin-bottom: 8px;
  margin-left: 28px;
  margin-top: 28px;
}
