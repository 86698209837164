.state-machine.error-container {
  margin: 0 0 1em 0;
  p {
    padding: 0;
    margin: 0 0 0 1em;
    color: red;
  }

  ul {
    margin: 1em 0 0 0;
    color: red;
  }
}
