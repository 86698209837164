@import 'Fonts';
@import 'Helpers';

@border: #d4d5d7;

.password-strength-container {
  #font.body-medium();

  .strength-header {
    color: @text-color;
    display: inline-block;
    margin-bottom: @base-spacing / 2;
  }

  .strength-text {
    display: inline-block;
    #font.body-small();
    font-weight: bold;
  }
}
