.shareable-details {
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 18px;
  padding-bottom: 18px;

  .title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .body {
    margin-top: 15px;
  }
}
