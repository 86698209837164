@import 'Fonts';
@import 'Env';

@left-navbar-bg: #3e474a;
@left-navbar-item-color: #aaa;
@left-navbar-item-selected-color: @white;
@left-navbar-item-small-color: #aaa;
@left-navbar-item-hover-color: @white;
@left-navbar-divider-bg: #aaa;
@left-navbar-title-color: @white;
@left-navbar-dropdown-title-color: @white;
@left-navbar-dropdown-name-color: @white;

@time-travel-container-bg: @white;
@time-travel-container-error-color: #f00;

.navbar-drawer-anchor-right {
  background-color: @left-navbar-bg !important;
  display: flex;
  flex-direction: column;
}

.left-navbar {
  position: relative;
  padding: 30px;
  max-width: 290px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;

  a {
    color: @left-navbar-item-color;
    margin-top: 15px;

    &:hover {
      color: @left-navbar-item-selected-color;
    }
  }

  .item {
    width: 100%;
    color: @left-navbar-item-color;
    font-size: 18px;
    border: none;
    text-align: left;
    margin: 12px 0;
    padding: 0;
    display: block;
    text-decoration: none;

    &.bottom {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      flex-grow: 1;
      justify-content: flex-end;

      * {
        margin-bottom: 0;
      }
    }

    &.is-selected {
      color: @left-navbar-item-selected-color;
      font-weight: bold;
    }

    &.grow {
      flex-grow: 1;
    }

    .icon {
      font-size: 24px;
      margin-right: 15px;
      display: inline;
      vertical-align: middle;
    }

    &.small {
      font-size: 12px;

      &:hover {
        cursor: auto;
        color: @left-navbar-item-small-color;
      }
    }

    &:hover {
      cursor: pointer;
      color: @left-navbar-item-hover-color;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: @left-navbar-divider-bg;
    margin: 20px 0;
  }

  .left-navbar-title-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .left-navbar-title {
    font-size: 36px;
    color: @left-navbar-title-color;
  }

  .page-header-user-type {
    margin: 0;
    padding: 0;
    border: none;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .dropdown-container {
    margin-bottom: 50px;

    .dropdown-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .dropdown-title {
      font-size: 12px;
      color: @left-navbar-dropdown-title-color;
    }

    .dropdown-name {
      font-weight: bold;
      color: @left-navbar-dropdown-name-color;
      flex: 1;
      cursor: pointer;
    }
  }

  .download-app-section {
    color: white;
    margin-bottom: 40px;

    h4 {
      font-size: 16px;
    }

    p {
      color: #fefeff;
      font-size: 14px;
    }

    button {
      color: white;
      border-color: white;

      &:hover {
        color: @left-navbar-bg;
      }
    }
  }
}

.time-travel {
  .MuiPaper-root {
    border-radius: 0;

    .time-travel-container {
      background: @time-travel-container-bg;
      padding: 10px;

      h4 {
        text-transform: uppercase;
        font-size: 11px;
      }

      input {
        min-width: 220px;
      }

      .error {
        color: @time-travel-container-error-color;
        font-size: 12px;
        margin-top: 5px;
      }

      hr {
        margin: 15px 0;
      }

      .buttons {
        display: flex;

        > button {
          margin-left: 5px;
        }

        > button:first-of-type {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .left-navbar {
    max-width: 240px;
  }
}
