@wysiwyg-preview-border: #000;

.wysiwyg-wrapper {
  display: flex;
  flex-direction: row;
}

.wysiwyg-blocks {
  width: 100%;
  max-width: 100%;
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.wysiwyg-markdown {
  width: 100%;
  max-width: 100%;
}

.wysiwyg-preview {
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  border: 1px solid @wysiwyg-preview-border;
}
