@import 'Colors';

@light-dialog-bg: #f5f9f9;

.light-dialog {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: @light-dialog-bg;
  z-index: 9999999999999; // Even higher than material UI dialogs

  @media screen and (max-width: 599px) {
    width: 100vw;
  }

  @media screen and (min-width: 600px) {
    min-width: 500px;
    max-width: 600px;
    min-height: 200px;
  }

  .close-dialog-icon-container {
    position: absolute;
    top: 30px;
    right: 28px;
    cursor: pointer;
    z-index: 9999999999999; // Even higher than the dialog and its other contents

    .close-dialog-icon {
      width: 16px;
      height: 16px;
    }
  }
}
