@import 'Colors';
@import 'Fonts';

@pd-scores-container-bg: @white;
@pd-scores-top-number-color: @green-medium;
@pd-scores-top-text-color: #333;
@pd-scores-bottom-positive-bg: #00ff0022;
@pd-scores-bottom-negative-bg: #e9998f22;
@pd-score-positive-icon-fill: green;
@pd-score-negative-icon-fill: @pink;
@pd-scores-bottom-text-pos-color: green;
@pd-scores-bottom-text-neg-color: @pink;
@pd-list-header-bar-bg: #333;
@pd-list-header-bottom-color: #333;
@pd-milestone-color: @green-dark;
@pd-list-divider-color: @gray-medium;

.pd-adherence-scores-list {
  display: flex;
  flex-direction: row;
  margin-left: 28px;
  margin-top: 28px;
  margin-right: 28px;

  .pd-scores-container {
    display: flex;
    flex-direction: column;
    background: @pd-scores-container-bg;
    box-shadow: @shadow-default;
    border-radius: 10px;

    #width-of-card();

    .pd-scores-top {
      display: flex;
      flex-direction: row;
      padding: 14px;
      align-items: center;

      .pd-scores-top-number {
        font-family: Lora;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        color: @pd-scores-top-number-color;
        margin-right: 18px;
      }

      .pd-scores-top-text {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: @pd-scores-top-text-color;
      }
    }

    .pd-scores-bottom {
      display: flex;
      flex-direction: row;

      padding: 14px;
      align-items: center;

      &.positive {
        background: @pd-scores-bottom-positive-bg;
      }

      &.negative {
        background: @pd-scores-bottom-negative-bg;
      }

      .pd-scores-bottom-icon {
        margin-right: 18px;

        .pd-score-positive-icon path {
          fill: @pd-score-positive-icon-fill;
        }

        .pd-score-negative-icon path {
          fill: @pd-score-negative-icon-fill;
        }
      }

      .pd-scores-bottom-text {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;

        &.positive {
          color: @pd-scores-bottom-text-pos-color;
        }
        &.negative {
          color: @pd-scores-bottom-text-neg-color;
        }
      }
    }
  }
}

// Header at the top of the entire page
.pd-page-header {
  margin-left: 28px;
  margin-top: 40px;

  .pd-page-header-top-text {
    #font.heading-1();
  }

  .pd-page-header-bottom-text {
    #font.body-medium();
    margin: 10px 15px 0px 0px;
    font-size: 20px;
    opacity: 0.7;
  }

  .success-img-container {
    margin-top: 90px;
    margin-bottom: 70px;

    .success-img {
      width: 700px;
      @media screen and (max-width: 700px) {
        width: calc(100vw + 200px);
        margin-left: -50px;
      }
    }
  }
}

// Header at the top of the list view

.pd-list-header {
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  margin-right: 48px;
  margin-top: 68px;

  .pd-list-header-bar {
    width: 55px;
    height: 2px;
    background-color: @pd-list-header-bar-bg;
  }

  .pd-list-header-bottom {
    display: flex;
    flex: 1;
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    margin-top: 8px;
  }
}

.pd-milestone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 68px 28px 0;
  color: @pd-milestone-color;

  .pd-milestone-title {
    #font.heading-3();
  }

  .pd-milestone-description {
    #font.body-medium();
    margin-top: 13px;
  }

  .pd-milestone-img {
    width: 63px;
    margin-left: 20px;
  }
}

.action-item-list {
  margin-bottom: 70px;
}

.pd-list-divider {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: @pd-list-divider-color;
  margin-left: 28px;
  margin-top: 28px;
  margin-bottom: 10px;
}

.pd-mid-section {
  margin-top: 48px;
}

// Deliberately not nested CSS, since other components use this (e.g. report event main)
.pd-small-header {
  #font.heading-3();
  font-weight: bold;
  margin-bottom: 22px;
}
