@import 'Fonts';

.settings-section-title-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
}

.settings-section-title-text {
  font-size: 18px;
}

.settings-section-content {
  margin-bottom: 28px;
}

.language-message-content {
  line-height: 25px;
  &.dynamic-form.participant-language-container {
    padding-top: 0;
  }
  .MuiTypography-subtitle2 {
    font-weight: bolder;
  }

  .MuiTypography-body2 {
    color: #bfc0c2;
    font-size: 12px;
  }
}
