@import 'Env';

@media only screen and (max-width: @isTabletSizedMaxWidth) {
  .bottom-button-container {
    width: 100%;
  }
}

@media only screen and (min-width: @isTabletSizedMaxWidth) {
  .bottom-button-container {
    min-width: 240px;
    margin: 0 10px 10px;
  }

  .bottom-buttons-container {
    margin-left: 26px;
    margin-right: 26px;
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
