@import 'Fonts';

@embedded-scheduler-error-container-bg: @white;

.embedded-scheduler-error-container {
  background-color: @embedded-scheduler-error-container-bg;
  padding: 28px;

  .title {
    #font.heading-1();
    margin-bottom: 18px;
  }

  .text-container {
    #font.body-medium();
  }
}
