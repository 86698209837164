.admin-tools-flex-container-row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  & > button {
    margin-right: 10px;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.admin-tools-header {
  align-items: center;
}

.admin-tools-content-tab {
  padding-bottom: 60px;
}
