@gtm-environ-text-color: rgba(0, 0, 0, 0.54);

.gtm-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .environment-block {
    display: flex;
    width: 240px;
    text-align: center;
    flex-direction: column;

    .environ-text {
      color: @gtm-environ-text-color;
      padding: 0;
      text-align: left;
      font-size: 1rem;
      font-family: Lato;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 5px;
    }
    .new-gtm {
      display: flex;
      flex-direction: row;
      width: 200px;
    }
  }
}
