@signature-container-border: #bbb;
@signature-container-bg: @white;
@signature-input-signing-color: #6e6e6e;
@signature-input-signing-border: #bbbbbb;

.signature-size {
  height: 300px;
}

.signature-container {
  .signature-size;
  border: 1px solid @signature-container-border;
  position: relative;
  background-color: @signature-container-bg;
}

.signature-canvas-container {
  .signature-size;
  position: absolute;
  left: 0px;
  top: 0px;
}

.signature-input-container {
  .signature-size;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
}

.signature-input-open-portion {
  flex: 1;
}

.signature-input-signing-portion {
  display: flex;
  font-size: 13px;
  color: @signature-input-signing-color;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: @signature-input-signing-border;
  padding-top: 10px;
  margin: 0 25px 15px;
  align-items: center;
  > span {
    flex: 1;
  }
  > button {
    z-index: 1;
  }
}
