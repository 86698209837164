@import 'Fonts';
@import 'Env';
@import 'Colors';

@outer-telemed-color: #ecf4f5;
@outer-telemed-title-color: #2d6d88;
@discrete-block-subtitle-color: #232323;
@discrete-block-small-header-color: #7a7a7a;
@discrete-block-paragraph-color: #2d6d88;
@discrete-block-big-button-bg: #5b9196;
@discrete-block-big-button-color: @white;

.outer-telemed {
  background-color: @outer-telemed-color;
  height: 100%;
  .telemed-waiting-room {
    width: calc(100% - 28px);
    align-self: center;
    padding-top: 40px;
    padding-left: 28px;
    > div {
      flex: 1;
    }

    .spacer {
      margin: 5px 0px;
    }

    .title {
      color: @outer-telemed-title-color;
      font-family: 'Lora';
      font-weight: bold;
      font-size: 27px;
      line-height: 1.3;
      text-align: left;
    }

    .discrete-block {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      min-height: 50px;
      margin-top: 30px;
      .subtitle {
        color: @discrete-block-subtitle-color;
        font-family: 'Lato';
        font-weight: bold;
        font-size: 20px;
        line-height: 1.3;
        text-align: left;
        margin-bottom: 10px;
      }
      .small-header {
        color: @discrete-block-small-header-color;
        font-family: 'Lato';
        font-weight: bold;
        font-size: 15px;
        line-height: 1.3;
        text-align: left;
      }
      .paragraph {
        color: @discrete-block-paragraph-color;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 20px;
        line-height: 1.3;
        text-align: left;
      }
      .big-button {
        width: 298px;
        height: 52px;
        padding: 8px 8px 8px 8px;
        box-shadow: @shadow-high;
      }
    }
    .telemed-room-button {
      width: 200px;
    }

    .join-button-container {
      overflow: hidden;
    }
  }
}
// less specificity for big-button, to allow custom styles to override it
.big-button {
  color: @discrete-block-big-button-color;
  background: @discrete-block-big-button-bg;
}
