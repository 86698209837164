@import 'Fonts';

@knowledge-content-container-bg: @white;
@knowledge-content-text-color: #2196f3;
@knowledge-content-link-a-color: #00c8f8;

.knowledge-content-container {
  background-color: @knowledge-content-container-bg;
  padding: 70px 90px;
  line-height: 24px;
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .knowledge-content-container {
    padding: 28px;
  }
}

.knowledge-content-section {
  &:not(:first-child) {
    margin-top: 48px;
  }
}

.knowledge-content-section-title {
  font-size: 18px;
  font-weight: 600;
}

.knowledge-content-video {
  margin-top: 28px;
}

.knowledge-content-text {
  font-size: 16px;

  a {
    color: @knowledge-content-text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    &:not(:first-of-type) {
      margin-top: 48px;
    }
  }
}

.knowledge-content-bullets {
  margin-top: 28px;
}

.knowledge-content-link-section {
  font-weight: bold;
  margin-top: 28px;
  font-size: 14px;
}

.knowledge-content-link {
  margin-top: 14px;
}

.knowledge-content-link-a {
  text-decoration-style: dotted;
  color: @knowledge-content-link-a-color;
  font-size: 14px;

  &:hover {
    text-decoration-style: solid;
  }
}
