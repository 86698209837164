.chevron-container {
  display: flex;
  flex-direction: row;
  height: 30px;
  flex-grow: 1;
  margin-bottom: 20px;
  margin-top: 15px;
  margin-right: 20px;
}

.chevron-segment {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 30px;
  margin-left: -5px;

  &:hover {
    cursor: pointer;
  }

  .chevron-segment-mask {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #ffffff;
    z-index: 2;
  }

  .chevron-segment-block {
    background-color: #eeeeee;
    flex: 1;
    height: 30px;
    margin-left: -15px;
    z-index: 1;
    &.completed {
      background-color: #ffb040;
    }
    &.in-progress {
      background-color: #ffe3ae;
    }
  }

  .chevron-segment-triangle {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #eeeeee;
    z-index: 1;
    &.completed {
      border-left: 15px solid #ffb040;
    }
    &.in-progress {
      border-left: 15px solid #ffe3ae;
    }
  }
}
