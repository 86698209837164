@import 'Colors';
@import 'Fonts';

@button-container-bg: @white;
@search-bar-container-bg: #bbb;
@search-bar-container-teal-border: @green-medium;
@filter-bg: @white;
@filter-border: #bbb;
@filter-color: rgba(0, 0, 0, 0.54);
@filter-hover-bg: #f9f9ff9;
@filter-teal-bg: @green-medium;
@filter-teal-border: @green-medium;
@filter-teal-color: @green-medium;
@search-filters-bg: @white;
@search-filters-border: #bbb;
@search-filters-bg: @white;
@search-filters-label-color: rgba(0, 0, 0, 0.54);

.button-container {
  padding: 15px;
  background: @button-container-bg;
}

.search-bar-container {
  border-style: solid;
  border-color: @search-bar-container-bg;
  border-width: 0px;
  border-bottom-width: 1px;
  padding: 0px;
  display: flex;
  flex-direction: row;
}

.search-bar-input {
  margin: 0;
  padding: 21px 28px;
  font-size: 18px;
  border-width: 0px;
  flex: 1;
  border-bottom: 2px solid transparent;

  &:focus {
    outline: none;
  }

  &.teal {
    border-bottom: 2px solid @search-bar-container-teal-border;
  }
}

.filter {
  background-color: @filter-bg;
  border-right: 1px solid @filter-border;
  padding: 6px 28px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  color: @filter-color;
  border-bottom: 2px solid transparent;

  &:hover {
    cursor: pointer;
    background-color: @filter-hover-bg;
  }

  &.teal {
    color: @filter-teal-bg;
    border-bottom: 2px solid @filter-teal-border;
  }

  svg {
    margin-right: 3px;
  }
}

li.teal {
  color: @filter-teal-color;
}

.search-filters {
  padding: 20px;
  background-color: @search-filters-bg;
  border-bottom: 1px solid @search-filters-border;
  border-top: 1px solid #bbb;

  .search-filters-label {
    margin-bottom: 15px;
    color: @search-filters-label-color;
  }
}
