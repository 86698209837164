@import 'Fonts';
@import 'Env';

@pin-input-input-border: #bbb;
@pin-input-has-error-border: #ff3823;

.pin-input-outside-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pin-input-container {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  width: 300px;
  align-items: center;
}

.pin-input-input {
  padding: 10px;
  border: 1px solid @pin-input-input-border;
  flex: 1;
  margin: 6px;
  max-width: 16px;
  text-align: center;

  &:focus {
    outline: none;
  }
}

.pin-input-has-error {
  border-color: transparent;
  border: 1px solid @pin-input-has-error-border;
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .pin-input-input {
    font-size: 16px;
  }
}

.mobile-pin-input-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.mobile-pin-input-row {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 280px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mobile-pin-input-button {
  flex: 1;
  display: flex;
  justify-content: center;
}

.mobile-pin-input-button-left {
  justify-content: flex-start;
}

.mobile-pin-input-button-center {
  justify-content: center;
}

.mobile-pin-input-button-right {
  justify-content: flex-end;
}

.mobile-pin-input-button-noop {
  visibility: hidden;
}
