@import 'Colors';

.tag {
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  display: inline-block;
  color: @neutral-100;
  font-weight: 700;

  &.tag-size-xs {
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &.tag-size-2xs {
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &.tag-color-red {
    background-color: @labels-red;
  }

  &.tag-color-gray {
    background-color: @gray-medium;
  }
}
