@import 'Fonts';
@import 'Env';

@header-bg: @white;
@header-border: #bbb;
@header-title-color: #444;

@header-emphasis-text-color: #ff9600;
@header-emphasis-text-bg: #ffe3ae;
@header-emphasis-text-border: #ff9600;

.header {
  background-color: @header-bg;
  border-bottom: 1px solid @header-border;
  display: flex;
  flex-direction: row;
  padding-top: 12px;

  .participant-name,
  .participant-trial-container,
  .participant-tag.subtag {
    transition: all 0.2s;
  }

  .sticky {
    box-shadow: 0 0 3px 3px rgb(0 0 0 / 10%);
    background-color: @header-bg;
    width: 100% !important;
    box-sizing: border-box;
    left: 0;
    padding-left: 28px;
    padding-right: 28px;
    z-index: 10;

    @media screen and (max-width: @isExtraSmallMaxWidth) {
      padding-left: 18px;
      padding-right: 18px;

      .header-back-button svg {
        width: 36px;
        height: 36px;
      }
    }

    .header-back-button svg {
      width: 24px !important;
      height: 24px !important;
    }

    .header-title-text {
      font-size: 20px;
      margin-top: 8px;
      padding-bottom: 22px;
    }

    .trial-row {
      padding-top: 8px;
      .non-arrow {
        @media screen and (max-width: @isExtraSmallMaxWidth) {
          .participant-trial-container {
            font-size: 11px;
          }

          .participant-tag.subtag {
            font-size: 10px;
          }
        }

        .participant-name {
          font-size: 16px;

          @media screen and (max-width: @isExtraSmallMaxWidth) {
            padding-top: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: @isExtraSmallMaxWidth) {
    padding-top: 0;

    .trial-row {
      .non-arrow {
        flex-direction: column;
        align-items: flex-start;

        .participant-name {
          margin-bottom: 12px;
        }
      }
    }
  }

  .before-title-wrapper {
    display: flex;
    flex-direction: row;
  }

  .header-back-button {
    border: none;
    margin-top: 4px;
    margin-right: 12px;
    cursor: pointer;
  }

  .header-back-button-mobile {
    border: none;
    margin-top: 4px;
    margin-left: -6px;
    margin-right: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .header-title {
    font-size: 36px;
    color: @header-title-color;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 28px;
  }

  .header-title-text {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    transition: all 0.2s;
    padding-bottom: 22px;

    @media screen and (max-width: @isExtraSmallMaxWidth) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .header-title-text-left {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header-emphasis-text {
    margin: 10px 38px 0 0;
    font-size: 18px;
    padding: 18px;
    border-width: 2px;
    border-style: solid;
    border-color: @header-emphasis-text-border;
    background-color: @header-emphasis-text-bg;
    color: @header-emphasis-text-color;
    font-weight: bold;
    border-radius: 5px;
  }

  .header-subtext {
    font-size: 18px;
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 15px;
    }

    p {
      margin: 0;
    }
  }

  .header-text-content {
    font-size: 14px;
    line-height: 21px;
    margin-top: 28px;
    margin-bottom: 18px;
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 28px;
    }
  }

  .header-menu {
    max-width: 240px;
    margin-right: 28px;

    &:last-child {
      margin-bottom: 28px;
    }
  }
}

.header-tabledata {
  margin: 5px 0 10px 2px;
}

.header-tabledata-row {
  display: flex;
  margin-top: 8px;

  &:first-child {
    margin-top: 18px;
  }
}

@media screen and (max-width: @isTabletSizedMaxWidth) {
  .header-tabledata-row {
    flex-direction: column;
  }
  .header-tabledata-row-button {
    margin-top: 6px;
  }
  .header-tabledata-row-right {
    margin-top: 6px;
  }
  .header-menu {
    margin-top: -14px;
    margin-bottom: 4px;
  }
}

@media screen and (min-width: @isTabletSizedMaxWidth) {
  .header-tabledata-row {
    flex-direction: row;
  }
  .header-tabledata-row-button {
    margin-left: 18px;
    margin-top: -15px;
    margin-bottom: -12px;
  }
}

@media screen and (max-width: @isExtraSmallMaxWidth) {
  .header {
    .header-title {
      margin: 18px;
    }

    .header-tabledata-row {
      font-size: 12px;
    }

    .header-tabledata-row-left {
      font-size: 16px;
    }

    .header-tabledata-row-right {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: @isExtraSmallMaxWidth) {
  .header-tabledata-row {
    font-size: 16px;
  }
}

.header-tabledata-row-left {
  width: 200px;
  font-weight: bold;
  margin-right: 15px;

  max-width: 200px;
  min-width: 140px;
  flex: 1;
}

.header-tabledata-row-icon {
  margin-right: 10px;
}

.header-tabledata-row-icon-text {
  margin-right: 10px;
}

.button-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .button-item {
    margin-right: 20px;
    margin-bottom: 20px;

    > button {
      min-width: 80%;
    }
  }
}
