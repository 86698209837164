@import 'Colors';
@import 'Fonts';

@single-select-bg: @white;
@single-select-border: @green-medium;
@single-select-color: #333;

.single-select {
  .ss-option {
    display: flex;
    flex-direction: row;
    background: @single-select-bg;
    border: 1px solid @single-select-border;
    box-sizing: border-box;
    box-shadow: @shadow-high;
    border-radius: 10px;
    #font.body-medium();
    line-height: 20px;
    color: @single-select-color;
    padding: 18px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 18px;
    }

    .ss-option-left {
      margin-right: 18px;
    }
  }
}
