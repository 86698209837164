@base-spacing: 15px;

/* Margin Top */
.mt-0-5 {
  margin-top: @base-spacing / 2 !important;
}

[class*='-mt-0-5'] {
  margin-top: -@base-spacing / 2 !important;
}

[class*='-mt-1'] {
  margin-top: -@base-spacing !important;
}

.mt-1 {
  margin-top: @base-spacing !important;
}

.mt-1-5 {
  margin-top: @base-spacing * 1.5 !important;
}

.mt-2 {
  margin-top: @base-spacing * 2 !important;
}

.mt-2-5 {
  margin-top: @base-spacing * 2.5 !important;
}

.mt-3 {
  margin-top: @base-spacing * 3 !important;
}

.mt-3-5 {
  margin-top: @base-spacing * 3.5 !important;
}
/* Margin Top */

/* Margin Bottom */
.mb-0-5 {
  margin-bottom: @base-spacing / 2 !important;
}

.mb-1 {
  margin-bottom: @base-spacing !important;
}

.mb-1-5 {
  margin-bottom: @base-spacing * 1.5 !important;
}

.mb-2 {
  margin-bottom: @base-spacing * 2 !important;
}

.mb-2-5 {
  margin-bottom: @base-spacing * 2.5 !important;
}

.mb-3 {
  margin-bottom: @base-spacing * 3 !important;
}

.mb-3-5 {
  margin-bottom: @base-spacing * 3.5 !important;
}
/* Margin Bottom */

.h-screen {
  height: 100vh;
}

.h-screen-80 {
  height: 80vh;
}

.h-screen-50 {
  height: 50vh;
}

.h-screen-30 {
  height: 30vh;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-full {
  min-height: 100%;
}

.h-full {
  height: 100%;
}

.line-through {
  text-decoration: line-through !important;
}
