.sig-content-container {
  display: flex;
  flex-direction: row;

  .signature-text {
    font-weight: 400;
    font-size: 12px;
  }

  .signature-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    height: 155px;
  }

  .sig-content-container {
    display: flex;
    flex-direction: row;

    break-inside: avoid;
  }

  .sig-shield {
    background: white;
    height: 38px;
    margin-right: -38px;
    margin-top: 28px;
    padding-bottom: 6px;
    padding-top: 6px;
    z-index: 50;
  }

  .sig-decorator {
    border-bottom-style: solid;
    border-color: #2a6d89;
    border-left-style: solid;
    border-radius: 25px 0px 0px 25px;
    border-top-style: solid;
    border-width: 1px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 8px;
    margin-top: 5px;
    padding-left: 25px;
  }

  .signature-uuid {
    padding-top: 20px;
  }

  .signature-primary-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .signature-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .signature {
    width: 250px;
    height: 75px;
  }

  .sig-image {
    height: 90%;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .name-date-box {
    height: 50px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .bold-name {
    width: 349px;
    color: #232323;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;
  }

  .date {
    width: 299px;
    height: 24px;
    color: #aaaaaa;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    text-align: left;
  }

  .answer {
    width: 436px;
    color: #232323;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;
  }

  .bottom-answer-spacer {
    margin-bottom: 50px;
  }

  .top-answer-spacer {
    padding-bottom: 10px;
  }

  .horizontal-line {
    width: 100%;
    border-color: #c0c0c0;
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .signOff-header {
    height: 31px;
    padding-left: 5px;
    display: flex;
    background-color: #c0c0c0;
    font-weight: bold;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 20px;
  }

  .notes-header {
    font-weight: 400;
    font-size: 28px;
    line-height: 1.3;
    text-align: left;
  }

  @media print {
    .footer {
      page-break-after: always;
    }

    .no-break {
      break-inside: avoid;
    }
  }

  .signOff-elem {
    margin-bottom: 8px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .attestation-block {
    margin-bottom: 14px;
  }

  .checkbox {
    height: 14px;
    width: 14px;
    margin-right: 10px;
  }
}
