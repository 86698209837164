@import 'Colors';

@expansion-panel-subtitle-color: @gray-medium;

.expansion-panel {
  .summary-container {
    height: 60px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .summary-info {
      display: flex;
      flex-direction: column;

      .title {
        flex: 1;
        font-size: 20px;
        margin-bottom: 0px;
      }

      .subtitle {
        font-size: 13px;
        font-style: italic;
        color: @expansion-panel-subtitle-color;
        margin-top: 4px;
      }
    }

    .summary-actions {
      display: flex;
    }
  }

  .details-container {
    font-size: 14px;
    margin-top: 10px;
    line-height: 21px;
  }
}
