@import 'Colors';

@dynamic-form-bg: @green-light;
@ct-dialog-header-bg: @green-light;
@ct-dialog-header-subtitle-color: @green-dark;
@ct-dialog-close-button-container-color: @green-dark;

.themed-dialog {
  .dynamic-form {
    background-color: @dynamic-form-bg;
  }

  .custom-dialog-header {
    background-color: @ct-dialog-header-bg;

    .custom-dialog-header-left {
      .custom-dialog-header-title {
        font-family: Montserrat;
        font-weight: bold;
      }

      .custom-dialog-header-title,
      .custom-dialog-header-subtitle {
        color: @ct-dialog-header-subtitle-color;
      }
    }
  }

  .custom-dialog-header-right {
    .custom-dialog-close-button-container {
      color: @ct-dialog-close-button-container-color;
    }
  }
}
